import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { AppDataContext } from 'context/appData';
import { ReactComponent as IconPlay } from 'i/icons/play.svg';
import { SplitText } from 'libs/SplitText';
import Vimeo from '@u-wave/react-vimeo';
import { gsap } from 'gsap';

import { TABLET_MEDIA_POINT } from 'utils/constant';
import { UiContext } from 'context/ui';

const HomeSliderVideoBlock = ({
	headerTitle,
	videoCover,
	vimeoVideoId,
	slidesAnimArray,
	slideActiveIndex,
	slideIndex,
	closeVideoButtonTitle,
	setVideoPopupOpen,
}) => {
	const { langApp } = useContext(AppDataContext);
	const { windowWidth } = useContext(UiContext);
	const $videoBlockContainer = useRef();
	const $videoBlockPreview = useRef();
	const $videoBlock = useRef();
	const $btnCloseVideo = useRef();
	const $crowdBlock = useRef();
	const $videoOverlay = useRef();
	const $videoHeaderTitle = useRef();
	const [isVideoPaused, setVideoPaused] = useState(true);
	const [isVideoLoaded, setVideoLoaded] = useState(false);
	const [isVideoInitialized, setVideoInitialized] = useState(false);

	const $playButton = useRef();
	const [videoAnimTl] = useState(gsap.timeline({
		paused: true,
	}));

	useEffect(() => {
		if (slidesAnimArray && slidesAnimArray.length) {
			const $document = document;

			let $headerEl = $document.querySelector('.header');
			let $faqBlock = $document.querySelector('.faq_block');
			let $pageBottomBlock = $document.querySelectorAll('.page_bottom_block');
			let $sideLogoW = $document.querySelector('.side_logo_w');
			let $sliderControls = $document.querySelector('.home_slider_controls_w');
			let $videoPopup = $document.querySelector('.videoPopup');

			const allGlobalElements = [$headerEl, $faqBlock, $pageBottomBlock, $sliderControls, $sideLogoW, $videoHeaderTitle.current];

			if (windowWidth >= TABLET_MEDIA_POINT) {
				const fromXVideoBlockContainerValue = 0.65;
				const fromYVideoBlockContainerValue = 0.65;

				videoAnimTl
					.addLabel('open_start')
					.to($videoPopup, {
						zIndex: 35,
						duration: 0.1,
					}, 'open_start')
					.fromTo($videoBlockContainer.current, {
						scaleX: fromXVideoBlockContainerValue,
						scaleY: fromYVideoBlockContainerValue,
						transformOrigin: '50% 0%',
					}, {
						scaleX: 1,
						scaleY: 1,
						zIndex: 20,
						duration: 0.8,
						ease: 'power2.inOut',
					}, 'open_start')
					.fromTo(slidesAnimArray[slideIndex].videoWrapper, {
						scale: 1,
					}, {
						scale: 1.6,
						duration: 0.8,
						immediateRender: false,
						ease: 'power2.inOut',
					}, 'open_start')
					.fromTo([$playButton.current, $videoOverlay.current], {
						opacity: gsap.utils.wrap([1, 0]),
					}, {
						opacity: gsap.utils.wrap([0, 1]),
						pointerEvents: 'none',
					}, 'open_start')
					.fromTo(allGlobalElements, {
						opacity: 1,
					}, {
						opacity: 0,
						pointerEvents: 'none',
						immediateRender: false,
					}, 'open_start')
					.fromTo([$videoBlockPreview.current, $videoBlock.current], {
						opacity: gsap.utils.wrap([1, 0]),
					}, {
						opacity: gsap.utils.wrap([0, 1]),
						pointerEvents: 'auto',
						immediateRender: false,
					}, 'open_start+=.2')
					.addLabel('open_end')
					.addLabel('close_start')
					.to($videoBlockContainer.current, {
						scaleX: fromXVideoBlockContainerValue,
						scaleY: fromYVideoBlockContainerValue,
						duration: 0.8,
						zIndex: 1,
						ease: 'power2.inOut',
						immediateRender: false,
					}, 'close_start')
					.fromTo(slidesAnimArray[slideIndex].videoWrapper, {
						scale: 1.6,
					}, {
						scale: 1,
						duration: 0.8,
						immediateRender: false,
						ease: 'power2.inOut',
					}, 'close_start')
					.fromTo([$videoOverlay.current, $btnCloseVideo.current], {
						opacity: 1,
					}, {
						opacity: 0,
						pointerEvents: 'none',
						immediateRender: false,
					}, 'close_start')
					.to($videoPopup, {
						zIndex: 25,
						immediateRender: false,
					}, 'close_start')
					.fromTo(allGlobalElements, {
						opacity: 0,
					}, {
						opacity: 1,
						pointerEvents: 'auto',
						immediateRender: false,
					}, 'close_start')
					.fromTo([$videoBlockPreview.current, $videoBlock.current], {
						opacity: gsap.utils.wrap([0, 1]),
					}, {
						opacity: gsap.utils.wrap([1, 0]),
						pointerEvents: 'nont',
						immediateRender: false,
					}, 'close_start')
					.fromTo([$playButton.current, $crowdBlock.current], {
						opacity: 0,
					}, {
						opacity: 1,
						pointerEvents: 'auto',
						immediateRender: false,
					}, 'close_start+=.8')
					.addLabel('close_end');

				if (slidesAnimArray[slideIndex] && slidesAnimArray[slideIndex].aboutVideoTitleWords && slidesAnimArray[slideIndex].aboutVideoTitleWords.chars) {
					videoAnimTl
						.set(slidesAnimArray[slideIndex].aboutVideoTitleWords.chars, {
							y: '-20rem',
							opacity: 0,
							duration: 0,
						}, 'open_start');

					videoAnimTl
						.fromTo(slidesAnimArray[slideIndex].aboutVideoTitleWords.chars, {
							y: '-20rem',
							opacity: 0,
						}, {
							y: '0rem',
							opacity: 1,
							stagger: 0.02,
							ease: 'power2.out',
							immediateRender: false,
						}, 'close_start');
				}

				let btnCloseVideoTitleSplitted;

				if ($btnCloseVideo.current) {
					btnCloseVideoTitleSplitted = new SplitText($btnCloseVideo.current, { type: 'chars,words', linesClass: 'home_split_letters' });
				}

				if (btnCloseVideoTitleSplitted) {
					videoAnimTl
						.to($btnCloseVideo.current, {
							opacity: 1,
							pointerEvents: 'auto',
							duration: 0,
						}, 'open_start')
						.fromTo(btnCloseVideoTitleSplitted.chars, {
							y: '-20rem',
							opacity: 0,
						}, {
							y: '0rem',
							opacity: 1,
							stagger: 0.02,
							ease: 'power2.out',
						}, 'open_start');
				}
			} else {
				videoAnimTl
					.addLabel('open_start')
					.to($videoPopup, {
						zIndex: 35,
						duration: 0.1,
					})
					.fromTo([$videoBlock.current, $btnCloseVideo.current], {
						opacity: 0,
					}, {
						opacity: 1,
						duration: 0.5,
						pointerEvents: 'auto',
					}, 'open_start')
					.fromTo(allGlobalElements, {
						opacity: 1,
					}, {
						opacity: 0,
						immediateRender: false,
						pointerEvents: 'none',
					}, 'open_start')
					.fromTo($playButton.current, {
						opacity: 1,
					}, {
						opacity: 0,
						pointerEvents: 'none',
					}, 'open_start')
					.addLabel('open_end')
					.addLabel('close_start')
					.fromTo([$videoBlock.current, $btnCloseVideo.current], {
						opacity: 1,
					}, {
						opacity: 0,
						duration: 0.5,
						immediateRender: false,
						pointerEvents: 'none',
					})
					.fromTo($playButton.current, {
						opacity: 0,
					}, {
						opacity: 1,
						pointerEvents: 'auto',
						immediateRender: false,
					}, 'close_start')
					.to($videoPopup, {
						zIndex: 25,
						immediateRender: false,
					}, 'close_start')
					.fromTo(allGlobalElements, {
						opacity: 0,
					}, {
						opacity: 1,
						pointerEvents: 'auto',
						immediateRender: false,
					}, 'close_start')
					.addLabel('close_end');
			}
		}
	}, [slidesAnimArray]);

	const onVideoLoaded = () => {
		if (!isVideoLoaded) {
			setVideoPaused(true);
			setVideoLoaded(true);
		}
	};

	const openVideoOnFullScreen = () => {
		videoAnimTl.tweenFromTo('open_start', 'open_end');

		setTimeout(() => {
			setVideoPaused(false);
			setVideoPopupOpen(true);
		}, 1200);
	};

	const closeVideoFromFullScreen = () => {
		videoAnimTl.tweenFromTo('close_start', 'close_end');
		setVideoPaused(true);
		setVideoPopupOpen(false);
	};

	useEffect(() => {
		if (slideActiveIndex === slideIndex && !isVideoInitialized) {
			setVideoInitialized(true);
		}
	}, [slideActiveIndex]);

	return (
		<>
			<div className="home_slider_overlay" ref={$videoOverlay} />
			<div className="home_slider_content_w offset_2_mod">
				{closeVideoButtonTitle && (
					<button
						type="button"
						className="btn_video_close position_mod"
						ref={$btnCloseVideo}
						onClick={closeVideoFromFullScreen}
					>
						{closeVideoButtonTitle[langApp]}
					</button>
				)}
				{headerTitle ? (
					<div className="video_header_title aboutVideoTitle" ref={$videoHeaderTitle}>{headerTitle[langApp]}</div>
				) : null}
				<div
					className="slide_video_block"
					onClick={openVideoOnFullScreen}
					role="button"
					tabIndex="0"
					onKeyDown={openVideoOnFullScreen}
				>
					<div className="slide_video_block__container" ref={$videoBlockContainer}>
						<div className="slide_video_block__img_w" ref={$videoBlockPreview}>
							<img className="slide_video_block__img" src={videoCover} alt="" />
						</div>
						<div className="slide_video_block__w" ref={$videoBlock}>
							<div className="slide_video_block__col">
								<div className="slide_video_block__col_in">
									{isVideoInitialized && (
										<Vimeo
											className="slide_video_block__el videoGridEl"
											video={vimeoVideoId}
											autoplay={false}
											autopause={false}
											muted
											loop
											paused={isVideoPaused}
											onLoaded={onVideoLoaded}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
					<button
						className="play_btn"
						// onClick={openVideoOnFullScreen}
						type="button"
						ref={$playButton}
					>
						<IconPlay className="icon icon-play size_mod" />
					</button>
				</div>
			</div>
		</>
	);
};

export default HomeSliderVideoBlock;
