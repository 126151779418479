import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';
import APP_DATA from 'utils/jsonAppData';
import FaqItem from './FaqItem';

const FaqResults = ({
	searchResults,
}) => {
	const { langApp } = useContext(AppDataContext);

	return (
		<div className="faq_result">
			<div className="faq_result_title">
				{
					searchResults.length === 1
						? APP_DATA.faqPage.foundSingleAnswer[langApp]
						: APP_DATA.faqPage.foundMultipleAnswersFmt[langApp].replace('{numAnswers}', searchResults.length)
				}
			</div>
			{searchResults && searchResults.length ? (
				<div className="faq_content">
					{searchResults.map(({
						question,
						answer,
					}) => {
						return (
							<FaqItem
								title={question}
								text={answer}
								fullMod
							/>
						);
					})}
				</div>
			) : null}
		</div>
	);
};

export default FaqResults;
