import React, { useState } from 'react';
import HomeSliderVideoBlock from './HomeSliderVideoBlock';
import HomeSliderContentItem from './HomeSliderContentItem';
import HomeSliderItemInnerContentSlider from './HomeSliderItemInnerContentSlider';
import HomeSliderVideoContainer from './HomeSliderVideoContainer';

const HomeSliderItem = ({
	slidesAnimArray,
	slideActiveIndex,
	slideIndex,
	slideDescription,
	slideHeadText,
	vimeoVideoId,
	videoCover,
	closeVideoButtonTitle,
	gridInfoItems,
	videos,
	isHomeSliderLoadAnimationDone,
	slideKey,
}) => {
	const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

	return (
		<div className="home_slider_item">
			<div className={`home_slider_item_in ${gridInfoItems ? 'info_mod' : ''} ${vimeoVideoId ? 'videoPopup' : ''}`}>
				{slideDescription && slideDescription.length ? (
					<HomeSliderItemInnerContentSlider
						slideDescription={slideDescription}
						parentSlideActiveIndex={slideActiveIndex}
						isHomeSliderLoadAnimationDone={isHomeSliderLoadAnimationDone}
					/>
				) : null}
				{vimeoVideoId || slideHeadText ? (
					<HomeSliderVideoBlock
						headerTitle={slideHeadText}
						videoCover={videoCover}
						vimeoVideoId={vimeoVideoId}
						closeVideoButtonTitle={closeVideoButtonTitle}
						slidesAnimArray={slidesAnimArray}
						slideActiveIndex={slideActiveIndex}
						slideIndex={slideIndex}
						setVideoPopupOpen={setVideoPopupOpen}
					/>
				) : null}
				{gridInfoItems ? (
					<HomeSliderContentItem
						infoItems={gridInfoItems}
					/>
				) : null}
			</div>
			{videos && videos !== '' ? (
				<HomeSliderVideoContainer
					slideKey={slideKey}
					videos={videos}
					slideIndex={slideIndex}
					slideActiveIndex={slideActiveIndex}
					isVideoPopupOpen={isVideoPopupOpen}
				/>
			) : null}
		</div>
	);
};

export default HomeSliderItem;
