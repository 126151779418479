import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';
import parse from 'html-react-parser';
import { RegistrationForm } from 'components/Form';

const InvitationRegistrationSection = ({
	sectionTitle,
	sectionDescr,
	registrationFormNote,
	registrationFormFields,
	buttonFormSubmit,
}) => {
	const { langApp } = useContext(AppDataContext);

	return (
		<section className="section bg_2_mod height_2_mod">
			<div className="section_in v2_mod">
				{sectionTitle && (
					<h2 className="section_title h4_mod align_mod color_2_mod style_mod offset_2_mod pos_3_mod">{parse(sectionTitle[langApp])}</h2>
				)}
				{sectionDescr && (
					<div className="section_descr size_mod color_mod align_mod offset_mod">{parse(sectionDescr[langApp])}</div>
				)}
				{registrationFormFields && registrationFormFields.length ? (
					<RegistrationForm
						formNote={registrationFormNote}
						formFields={registrationFormFields}
						buttonFormSubmit={buttonFormSubmit}
					/>
				) : null}
			</div>
		</section>
	);
};

export default InvitationRegistrationSection;
