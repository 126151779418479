import React, { useContext } from 'react';
import { UiContext } from 'context/ui';

const MenuTrigger = React.forwardRef(({
	title,
}, ref) => {
	const {
		setMainMenuOpen,
		isMainMenuAnimationActive,
	} = useContext(UiContext);

	const handleMenuOpen = () => {
		if (!isMainMenuAnimationActive) {
			setMainMenuOpen(prevState => !prevState);
		}
	};

	return (
		<button
			className="menu_trigger"
			onClick={handleMenuOpen}
			type="button"
			ref={ref}
			disabled={isMainMenuAnimationActive}
		>
			<span className="menu_trigger_title">
				{title}
			</span>
		</button>
	);
});

export default MenuTrigger;
