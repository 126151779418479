import React, { useContext, useEffect, useState } from 'react';
import { AppDataContext } from 'context/appData';
import { getPageById } from 'utils';
import { gsap } from 'gsap';
import {
	FaqContainer,
} from 'components/Faq';
import { BtnChat } from 'components/Buttons';
import { ReactComponent as ArrowDownIcon } from 'i/icons/arrow_down.svg';
import { UiContext } from 'context/ui';
import { GLOBAL_PAGE_ANIM_DELAY } from 'utils/constant';
import { VideoBlock } from 'components/VideoBlock';

const FaqPage = () => {
	const {
		langApp,
		pagesData,
	} = useContext(AppDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const { actionBannerAnimTl } = useContext(UiContext);
	const { themeSettings } = useContext(AppDataContext);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 544);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.data) {
			setCurrentPageData(preparedCurrentPageData.data);
		}
	};

	const pageAnim = () => {
		const sectionPageAnim = gsap.timeline({
			delay: GLOBAL_PAGE_ANIM_DELAY,
		});

		sectionPageAnim
			.addLabel('start')
			.add(actionBannerAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start');
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	useEffect(() => {
		if (currentPageData) {
			pageAnim();
		}
	}, [currentPageData]);

	return (
		currentPageData ? (
			<section className="section faq_mod">
				{themeSettings && themeSettings.faqs_page_videos ? (
					<VideoBlock videos={themeSettings.faqs_page_videos} />
				) : null}
				<div className="popup_scroll_icon v2_mod">
					<ArrowDownIcon className="icon icon-arrow_down size_mod" />
				</div>
				{currentPageData.faq_chat_button && currentPageData.faq_chat_button.title && (
					<BtnChat
						title={currentPageData.faq_chat_button.title[langApp]}
						v2Mod
					/>
				)}
				<div className="popup_content page_mod">
					<div className="popup_content_in page_mod">
						{currentPageData.page_title && (
							<div className="popup_title">{currentPageData.page_title[langApp]}</div>
						)}
						{currentPageData.page_subtitle && (
							<div className="popup_descr">{currentPageData.page_subtitle[langApp]}</div>
						)}
						<FaqContainer />
					</div>
				</div>
			</section>
		) : null
	);
};

export default FaqPage;
