import React from 'react';
import i18n, { availableLanguages } from 'utils/i18n';

const ReturnRackLangList = () => {
	const handleLangChange = (lang) => {
		i18n.changeLanguage(lang);
	};

	return (
		<ul className="rr_lang_list">
			{Object.keys(availableLanguages).map((code, index) => {
				let upperCaseCodeValue = code.toUpperCase();
				let lowerCaseCodeValue = code.toLowerCase();

				return (
					<li className="rr_lang_item" key={index}>
						<button
							className={`main_menu_lang_link ${i18n.language === lowerCaseCodeValue ? 'active_mod' : ''}`}
							onClick={() => handleLangChange(code)}
							type="button"
						>
							{upperCaseCodeValue}
						</button>
					</li>
				);
			})}
		</ul>
	);
};

export default ReturnRackLangList;
