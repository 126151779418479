import React from 'react';
import MapMarkerPopup from './MapMarkerPopup';

const MapMarker = React.forwardRef(({
	isMapOpen,
	setMapOpen,
	markerPopupData,
}, ref) => {
	return (
		<div className="map_marker" ref={ref}>
			{isMapOpen && markerPopupData ? (
				<MapMarkerPopup
					setMapOpen={setMapOpen}
					markerPopupData={markerPopupData}
				/>
			) : null}
			<div className="map_marker_icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.12 35.62">
					<g>
						<g>
							<path d="M27.6,32.55a.15.15,0,0,1,.13.26A18.06,18.06,0,1,1,13.89,0a.15.15,0,0,1,.1.27A17.52,17.52,0,0,0,27.6,32.55Zm29-6.33a.41.41,0,0,1-.45-.3l-3.47-6.74a.17.17,0,0,0-.16-.09H50.3c-.12,0-.18.06-.18.19v6.64a.29.29,0,0,1-.3.3H46a.3.3,0,0,1-.3-.3V6.1a.3.3,0,0,1,.3-.3h7.75c4.18,0,7,2.52,7,6.52a6.47,6.47,0,0,1-3.48,6,.19.19,0,0,0-.08.24h0l3.94,7.29a.23.23,0,0,1-.21.36Zm-.33-13.9a2.56,2.56,0,0,0-2.77-2.71H50.3a.17.17,0,0,0-.18.19v5.06c0,.13.06.2.18.2h3.21a2.58,2.58,0,0,0,2.77-2.74ZM12.1,16c0-3.15.12-4.21.58-5.53,1.08-3.33,3.43-5,7-5,5.18,0,7.16,2.61,7.07,5.68a.25.25,0,0,1-.28.27H22.89c-.2,0-.28-.12-.33-.3-.28-1.17-1.13-1.75-2.84-1.75a2.72,2.72,0,0,0-2.81,2.25c-.24.75-.33,1.48-.33,4.39s.09,3.64.33,4.39a2.72,2.72,0,0,0,2.81,2.25c1.71,0,2.68-.46,2.84-1.72,0-.19.12-.31.33-.31h3.59a.25.25,0,0,1,.28.27c.12,3.07-1.43,5.67-7.07,5.67a6.73,6.73,0,0,1-7-5C12.23,20.22,12.1,19.16,12.1,16Zm21.3,6.28H35a.26.26,0,0,1,.24.36A13.19,13.19,0,0,1,33.07,26a.63.63,0,0,1-.47.2H29.13a.34.34,0,0,1-.34-.33V6.1a.29.29,0,0,1,.3-.3h6.55c3.57,0,6.07,1.53,7.06,4.59.36,1.14.54,2.29.54,5.62A23.47,23.47,0,0,1,43,20.32c-.82,3.87-3.78,5.5-6.92,5.84a.19.19,0,0,1-.17-.3,15.92,15.92,0,0,0,2.83-9.21A18.6,18.6,0,0,0,38.43,12c-.51-1.65-1.74-2.25-3.79-2.25H33.38a.18.18,0,0,0-.18.18l0,12.2a.18.18,0,0,0,.18.18Z" fill="none" />
							<path d="M27.6,32.55a.15.15,0,0,1,.13.26A18.06,18.06,0,1,1,13.89,0a.15.15,0,0,1,.1.27A17.52,17.52,0,0,0,27.6,32.55Zm29-6.33a.41.41,0,0,1-.45-.3l-3.47-6.74a.17.17,0,0,0-.16-.09H50.3c-.12,0-.18.06-.18.19v6.64a.29.29,0,0,1-.3.3H46a.3.3,0,0,1-.3-.3V6.1a.3.3,0,0,1,.3-.3h7.75c4.18,0,7,2.52,7,6.52a6.47,6.47,0,0,1-3.48,6,.19.19,0,0,0-.08.24h0l3.94,7.29a.23.23,0,0,1-.21.36Zm-.33-13.9a2.56,2.56,0,0,0-2.77-2.71H50.3a.17.17,0,0,0-.18.19v5.06c0,.13.06.2.18.2h3.21a2.58,2.58,0,0,0,2.77-2.74ZM12.1,16c0-3.15.12-4.21.58-5.53,1.08-3.33,3.43-5,7-5,5.18,0,7.16,2.61,7.07,5.68a.25.25,0,0,1-.28.27H22.89c-.2,0-.28-.12-.33-.3-.28-1.17-1.13-1.75-2.84-1.75a2.72,2.72,0,0,0-2.81,2.25c-.24.75-.33,1.48-.33,4.39s.09,3.64.33,4.39a2.72,2.72,0,0,0,2.81,2.25c1.71,0,2.68-.46,2.84-1.72,0-.19.12-.31.33-.31h3.59a.25.25,0,0,1,.28.27c.12,3.07-1.43,5.67-7.07,5.67a6.73,6.73,0,0,1-7-5C12.23,20.22,12.1,19.16,12.1,16Zm21.3,6.28H35a.26.26,0,0,1,.24.36A13.19,13.19,0,0,1,33.07,26a.63.63,0,0,1-.47.2H29.13a.34.34,0,0,1-.34-.33V6.1a.29.29,0,0,1,.3-.3h6.55c3.57,0,6.07,1.53,7.06,4.59.36,1.14.54,2.29.54,5.62A23.47,23.47,0,0,1,43,20.32c-.82,3.87-3.78,5.5-6.92,5.84a.19.19,0,0,1-.17-.3,15.92,15.92,0,0,0,2.83-9.21A18.6,18.6,0,0,0,38.43,12c-.51-1.65-1.74-2.25-3.79-2.25H33.38a.18.18,0,0,0-.18.18l0,12.2a.18.18,0,0,0,.18.18Z" fill="#031123" fillOpacity="0.31" />
						</g>
						<path d="M27.6,32.55a.15.15,0,0,1,.13.26A18.06,18.06,0,1,1,13.89,0a.15.15,0,0,1,.1.27A17.52,17.52,0,0,0,27.6,32.55Zm29-6.33a.41.41,0,0,1-.45-.3l-3.47-6.74a.17.17,0,0,0-.16-.09H50.3c-.12,0-.18.06-.18.19v6.64a.29.29,0,0,1-.3.3H46a.3.3,0,0,1-.3-.3V6.1a.3.3,0,0,1,.3-.3h7.75c4.18,0,7,2.52,7,6.52a6.47,6.47,0,0,1-3.48,6,.19.19,0,0,0-.08.24h0l3.94,7.29a.23.23,0,0,1-.21.36Zm-.33-13.9a2.56,2.56,0,0,0-2.77-2.71H50.3a.17.17,0,0,0-.18.19v5.06c0,.13.06.2.18.2h3.21a2.58,2.58,0,0,0,2.77-2.74ZM12.1,16c0-3.15.12-4.21.58-5.53,1.08-3.33,3.43-5,7-5,5.18,0,7.16,2.61,7.07,5.68a.25.25,0,0,1-.28.27H22.89c-.2,0-.28-.12-.33-.3-.28-1.17-1.13-1.75-2.84-1.75a2.72,2.72,0,0,0-2.81,2.25c-.24.75-.33,1.48-.33,4.39s.09,3.64.33,4.39a2.72,2.72,0,0,0,2.81,2.25c1.71,0,2.68-.46,2.84-1.72,0-.19.12-.31.33-.31h3.59a.25.25,0,0,1,.28.27c.12,3.07-1.43,5.67-7.07,5.67a6.73,6.73,0,0,1-7-5C12.23,20.22,12.1,19.16,12.1,16Zm21.3,6.28H35a.26.26,0,0,1,.24.36A13.19,13.19,0,0,1,33.07,26a.63.63,0,0,1-.47.2H29.13a.34.34,0,0,1-.34-.33V6.1a.29.29,0,0,1,.3-.3h6.55c3.57,0,6.07,1.53,7.06,4.59.36,1.14.54,2.29.54,5.62A23.47,23.47,0,0,1,43,20.32c-.82,3.87-3.78,5.5-6.92,5.84a.19.19,0,0,1-.17-.3,15.92,15.92,0,0,0,2.83-9.21A18.6,18.6,0,0,0,38.43,12c-.51-1.65-1.74-2.25-3.79-2.25H33.38a.18.18,0,0,0-.18.18l0,12.2a.18.18,0,0,0,.18.18Z" fill="#4a7e9f" />
						<path d="M27.6,32.55a.15.15,0,0,1,.13.26A18.06,18.06,0,1,1,13.89,0a.15.15,0,0,1,.1.27A17.52,17.52,0,0,0,27.6,32.55Zm29-6.33a.41.41,0,0,1-.45-.3l-3.47-6.74a.17.17,0,0,0-.16-.09H50.3c-.12,0-.18.06-.18.19v6.64a.29.29,0,0,1-.3.3H46a.3.3,0,0,1-.3-.3V6.1a.3.3,0,0,1,.3-.3h7.75c4.18,0,7,2.52,7,6.52a6.47,6.47,0,0,1-3.48,6,.19.19,0,0,0-.08.24h0l3.94,7.29a.23.23,0,0,1-.21.36Zm-.33-13.9a2.56,2.56,0,0,0-2.77-2.71H50.3a.17.17,0,0,0-.18.19v5.06c0,.13.06.2.18.2h3.21a2.58,2.58,0,0,0,2.77-2.74ZM12.1,16c0-3.15.12-4.21.58-5.53,1.08-3.33,3.43-5,7-5,5.18,0,7.16,2.61,7.07,5.68a.25.25,0,0,1-.28.27H22.89c-.2,0-.28-.12-.33-.3-.28-1.17-1.13-1.75-2.84-1.75a2.72,2.72,0,0,0-2.81,2.25c-.24.75-.33,1.48-.33,4.39s.09,3.64.33,4.39a2.72,2.72,0,0,0,2.81,2.25c1.71,0,2.68-.46,2.84-1.72,0-.19.12-.31.33-.31h3.59a.25.25,0,0,1,.28.27c.12,3.07-1.43,5.67-7.07,5.67a6.73,6.73,0,0,1-7-5C12.23,20.22,12.1,19.16,12.1,16Zm21.3,6.28H35a.26.26,0,0,1,.24.36A13.19,13.19,0,0,1,33.07,26a.63.63,0,0,1-.47.2H29.13a.34.34,0,0,1-.34-.33V6.1a.29.29,0,0,1,.3-.3h6.55c3.57,0,6.07,1.53,7.06,4.59.36,1.14.54,2.29.54,5.62A23.47,23.47,0,0,1,43,20.32c-.82,3.87-3.78,5.5-6.92,5.84a.19.19,0,0,1-.17-.3,15.92,15.92,0,0,0,2.83-9.21A18.6,18.6,0,0,0,38.43,12c-.51-1.65-1.74-2.25-3.79-2.25H33.38a.18.18,0,0,0-.18.18l0,12.2a.18.18,0,0,0,.18.18Z" />
					</g>
				</svg>
			</div>
		</div>
	);
});

export default MapMarker;
