import React from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowLeftIcon } from 'i/icons/left.svg';
import { ReactComponent as ArrowRightIcon } from 'i/icons/right.svg';

const SliderControl = React.forwardRef(({
	prevMod,
	nextMod,
	teamMod,
	activeMod,
	onClickHandler,
}, ref) => {
	const sliderControlClasses = classNames('slider_control', {
		prev_mod: prevMod,
		next_mod: nextMod,
		team_mod: teamMod,
		active_mod: activeMod,
	});

	return (
		<button
			className={sliderControlClasses}
			onClick={onClickHandler}
			type="button"
			ref={ref}
		>
			{prevMod && (
				<ArrowLeftIcon className="icon icon-left size_mod" />
			)}
			{nextMod && (
				<ArrowRightIcon className="icon icon-left size_mod" />
			)}
		</button>
	);
});

export default SliderControl;
