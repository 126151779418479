import React from 'react';

const BodyBg = () => {
	return (
		<div className="body_bg_w">
			<svg className="body_bg_in" preserveAspectRatio="xMinYMid slice" data-name="ac43bf30-760b-46db-954e-cf64809d0355" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
				<defs>
					<radialGradient id="lgrad" cx="50%" cy="50%" r="808.45" gradientUnits="userSpaceOnUse">
						<stop offset={0} stopColor="rgb(235,235,235)" />
						<stop offset={1} stopColor="rgb(135,135,135)" />
					</radialGradient>
				</defs>
				<rect x="0" y="0" width="100%" height="100%" fill="url(#lgrad)" transform="scale(1 3) translate(0, -25)" />
			</svg>
		</div>
	);
};

export default BodyBg;
