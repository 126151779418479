import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { ReactComponent as ChatIcon } from 'i/icons/chat.svg';

const BtnChat = React.forwardRef(({
	title,
	v2Mod,
}, ref) => {
	const btnChatClasses = classNames('btn_chat', {
		v2_mod: v2Mod,
	});

	const handleOnClick = () => {
		if (window.$crisp) {
			window.$crisp.push(['do', 'chat:toggle']);
		}
	};

	return (
		<button
			className={btnChatClasses}
			ref={ref}
			type="button"
			onClick={handleOnClick}
		>
			<div className="btn_chat_icon">
				<ChatIcon className="icon icon-chat faq_mod" />
			</div>
			{title && (
				<div className="btn_chat_title">{parse(title)}</div>
			)}
		</button>
	);
});

export default BtnChat;
