import React, { useContext, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { SliderControl } from 'components/SliderControl';
import { gsap } from 'gsap';
import { TABLET_MEDIA_POINT } from 'utils/constant';
import { UiContext } from 'context/ui';
import { debounce } from 'utils';
import TeamPerson from './TeamPerson';

const TeamSlider = ({
	employees,
}) => {
	const teamSliderInstance = useRef();
	const currentTeamSlide = useRef(1);
	const slidesToShow = useRef(2);
	const allTeamItemElements = useRef([]);
	const isTeamSliderAnimDone = useRef(false);
	const isFirstSlideAnimDone = useRef(false);
	const { windowWidth } = useContext(UiContext);

	const $prevArrowEl = useRef();
	const $nextArrowEl = useRef();

	const arrowControlsAnim = ($arrowEl, nextArrow) => {
		const preparedTransformOrigin = !nextArrow ? '100% 100%' : '0% 0%';

		gsap.fromTo($arrowEl, {
			scale: 0,
			transformOrigin: preparedTransformOrigin,
		}, {
			scale: 1,
			duration: 0.8,
			delay: 0.5,
		});
	};

	const onBeforeChange = (currentSlide, nextSlide) => {
		const staggerAnimDir = currentSlide < nextSlide ? 0.06 : -0.06;
		const xPercentDir = currentSlide < nextSlide ? 100 : -100;

		isTeamSliderAnimDone.current = true;
		currentTeamSlide.current = nextSlide;

		const isPrevArrowHasClass = $prevArrowEl.current.classList.contains('active_mod');
		const isNextArrowHasClass = $nextArrowEl.current.classList.contains('active_mod');

		// PREV ARROW LOGIC
		if (currentTeamSlide.current === 1 && isPrevArrowHasClass) {
			$prevArrowEl.current.classList.remove('active_mod');
		}

		if (
			currentTeamSlide.current > 1
			&& !isPrevArrowHasClass
		) {
			$prevArrowEl.current.classList.add('active_mod');

			arrowControlsAnim($prevArrowEl.current);
		}

		// NEXT ARROW LOGIC
		if (currentTeamSlide.current + slidesToShow.current === allTeamItemElements.current.length) {
			$nextArrowEl.current.classList.remove('active_mod');
		}

		if (currentTeamSlide.current + slidesToShow.current <= allTeamItemElements.current.length && !isNextArrowHasClass) {
			$nextArrowEl.current.classList.add('active_mod');

			arrowControlsAnim($nextArrowEl.current, true);
		}

		if (windowWidth >= TABLET_MEDIA_POINT) {
			gsap.fromTo(allTeamItemElements.current, {
				xPercent: xPercentDir,
			}, {
				xPercent: 0,
				stagger: staggerAnimDir,
				ease: 'power1.inOut',
				duration: 1,
				onComplete: () => {
					isTeamSliderAnimDone.current = false;
				},
			});
		}
	};

	const handleSlidePrevClick = () => {
		if (!isTeamSliderAnimDone.current && currentTeamSlide.current > 1) {
			teamSliderInstance.current.slickPrev();
		}
	};

	const handleSlideNextClick = () => {
		if (currentTeamSlide.current === teamSliderInstance.current.props.initialSlide && !isFirstSlideAnimDone.current) {
			teamSliderInstance.current.slickGoTo(2);
			isFirstSlideAnimDone.current = true;
		}
		if (
			!isTeamSliderAnimDone.current
			&& (currentTeamSlide.current + slidesToShow.current) !== allTeamItemElements.current.length) {
			teamSliderInstance.current.slickNext();
		}
	};

	const handleScroll = (e) => {
		if (teamSliderInstance.current && !isTeamSliderAnimDone.current) {
			if (e.deltaY < 0 || e.deltaX < 0) {
				handleSlidePrevClick();
			} else if (e.deltaY > 0 || e.deltaX > 0) {
				handleSlideNextClick();
			}
		}
	};

	useEffect(() => {
		if (employees) {
			if (windowWidth >= TABLET_MEDIA_POINT) {
				window.addEventListener('wheel', debounce(handleScroll, 35, true));
			}
		}

		return () => {
			if (windowWidth >= TABLET_MEDIA_POINT) {
				window.removeEventListener('wheel', debounce);
			}
		};
	}, [employees]);

	return (
		<div className="team_slider_wrap">
			<SliderControl
				teamMod
				prevMod
				ref={$prevArrowEl}
				onClickHandler={handleSlidePrevClick}
			/>
			<SliderControl
				teamMod
				nextMod
				activeMod
				ref={$nextArrowEl}
				onClickHandler={handleSlideNextClick}
			/>
			{employees && employees.length ? (
				<Slider
					className="team_slider"
					ref={teamSliderInstance}
					{...{
						infinite: false,
						slidesToShow: slidesToShow.current,
						slidesToScroll: 1,
						initialSlide: windowWidth >= TABLET_MEDIA_POINT ? currentTeamSlide.current : 0,
						variableWidth: true,
						dots: false,
						arrows: false,
						touchMove: false,
						draggable: false,
						centerMode: true,
						centerPadding: '150px',
						cssEase: windowWidth >= TABLET_MEDIA_POINT ? 'none' : '',
						beforeChange: (current, next) => onBeforeChange(current, next),
						responsive: [
							{
								breakpoint: 1024,
								settings: {
									dots: true,
									slidesToShow: 1,
									centerPadding: 0,
									centerMode: true,
									touchMove: true,
								},
							},
						],
					}}
				>
					{employees.map(({
						name,
						quote,
						email,
						phone,
						media,
						job,
					}, index) => {
						return (
							<div
								className="team_item_wrap"
								ref={ref => { allTeamItemElements.current[index] = ref; }}
								key={index}
							>
								<TeamPerson
									name={name}
									quote={quote}
									media={media}
									email={email}
									phone={phone}
									job={job}
								/>
							</div>
						);
					})}
				</Slider>
			) : null}
		</div>
	);
};

export default TeamSlider;
