import React, {
	useContext,
	useEffect,
	useRef,
} from 'react';
import { UiContext } from 'context/ui';
import { gsap } from 'gsap';
import { Transition } from 'react-transition-group';
import { AppDataContext } from 'context/appData';
import LogoImage from 'i/logo.svg';

const Preloader = () => {
	const {
		isPreloaderActive,
		setPreloaderActive,
	} = useContext(UiContext);

	const { isAppDataLoaded } = useContext(AppDataContext);

	const $preloader = useRef(null);
	const $preloaderLogo = useRef(null);

	useEffect(() => {
		const preloaderTl = gsap.timeline();

		preloaderTl
			.addLabel('anim')
			.to($preloaderLogo.current, {
				opacity: 1,
			}, 'anim')
			.fromTo($preloaderLogo.current, {
				scale: 1,
			}, {
				scale: 1.3,
				repeat: -1,
				duration: 1,
				ease: 'power1.inOut',
				yoyo: true,
			}, 'anim');
	}, []);

	useEffect(() => {
		if (isAppDataLoaded) {
			gsap.delayedCall(2, () => {
				setPreloaderActive(false);
			});
		}
	}, [isAppDataLoaded]);

	const onExit = node => {
		gsap.fromTo(
			node,
			{
				opacity: 1,
			},
			{
				opacity: 0,
				ease: 'power2.out',
				duration: 1,
			},
		);
	};

	return (
		<Transition
			in={isPreloaderActive}
			timeout={2500}
			onExit={onExit}
			mountOnEnter
			unmountOnExit
		>
			<div className="preloader" ref={$preloader}>
				<div
					className="preloader_logo"
					ref={$preloaderLogo}
				>
					<img
						className="preloader_logo_img"
						src={LogoImage}
						alt="CDR Logo"
					/>
				</div>
			</div>
		</Transition>
	);
};

export default Preloader;
