/* eslint-disable react/react-in-jsx-scope,camelcase */
import { useState, useEffect, useContext } from 'react';
import { gsap } from 'gsap';
import { AppDataContext } from 'context/appData';
import { SanitizedText } from 'components/SanitizedText';
import { getPageById } from 'utils';
import { filesByLang, GLOBAL_PAGE_ANIM_DELAY } from 'utils/constant';
import { DownloadLink } from 'components/DownloadLink';
import { UiContext } from 'context/ui';

const PyroguardPage = () => {
	const { langApp, pagesData } = useContext(AppDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const {
		actionBannerAnimTl,
		faqBlockAnimTl,
		isPreloaderActive,
	} = useContext(UiContext);

	const [pageAnimation] = useState(gsap.timeline({
		paused: true,
	}));

	const pageAnim = () => {
		if (actionBannerAnimTl.progress() === 0 && faqBlockAnimTl.progress() === 0) {
			pageAnimation
				.addLabel('start')
				.add(actionBannerAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start')
				.add(faqBlockAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start+=.2');
		}
	};

	useEffect(() => {
		const currentData = getPageById(pagesData, 1544);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.data) {
			setCurrentPageData(preparedCurrentPageData.data);
		}
	}, [pagesData]);

	useEffect(() => {
		if (currentPageData) {
			pageAnim();
		}
	}, [currentPageData]);

	useEffect(() => {
		if (!isPreloaderActive) {
			gsap.delayedCall(GLOBAL_PAGE_ANIM_DELAY, () => {
				pageAnimation.play();
			});
		}
	}, [isPreloaderActive]);

	if (!currentPageData) return null;

	return (
		<section className="section pyroguard">
			<h1 className="pyroguard__title">
				{currentPageData.page_title[langApp]}
			</h1>
			<h2 className="pyroguard__subtitle">
				<SanitizedText>
					{currentPageData.page_subtitle[langApp]}
				</SanitizedText>
			</h2>
			<div className="pyroguard__img_w">
				<img
					className="pyroguard__img"
					src={currentPageData.page_image}
					alt="pyroguard logo"
				/>
			</div>
			<div className="pyroguard__content">
				<div className="pyroguard__list">
					<SanitizedText>
						{currentPageData.page_description[langApp]}
					</SanitizedText>
				</div>
				<ul className="pyroguard__documents">
					{currentPageData[filesByLang(langApp)].file.map(
						({
							file_icon, title, name, uploaded_file, type, size,
						}) => (
							<li className="pyroguard__documents_item" key={name}>
								<DownloadLink file_icon={file_icon} title={title} name={name} uploaded_file={uploaded_file} type={type} size={size} />
							</li>
						),
					)}
				</ul>
			</div>
		</section>
	);
};

export default PyroguardPage;
