import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';
import { useNavigate } from 'react-router-dom';

const SideLogoList = React.forwardRef(({
	props,
}, ref) => {
	const { themeSettings } = useContext(AppDataContext);
	const navigate = useNavigate();

	const handleClick = (event, url) => {
		event.preventDefault();

		const website = process.env.REACT_APP_WEBSITE;
		if (url.startsWith(website)) {
			navigate(url.replace(website, '')); // Navigate to the internal path
		} else {
			window.location.href = url; // Handle external link
		}
	};

	return (
		themeSettings && themeSettings.general_settings && (
			<div className="side_logo_w js-global-element" ref={ref}>
				<div className="side_logo_in">
					<ul className="side_logo_list">
						{themeSettings.general_settings.side_logos && themeSettings.general_settings.side_logos.map(({
							logo, link,
						}, index) => {
							const logoEl = logo.url ? (
								<img className="side_logo_img" src={logo.url} alt={logo.alt} />
							) : null;
							return (
								<li className="side_logo_item" key={index}>
									<div className="side_logo_item_in">
										{
											logoEl && link
												? <a href={link} onClick={(event) => handleClick(event, link)}>{logoEl}</a>
												: logoEl
										}
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		)
	);
});

export default SideLogoList;
