/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';
import parse from 'html-react-parser';
import { isObject } from 'formik';

const InvitationGameSection = ({
	sectionTitle,
	gameplanDescription,
	gameCalendarList,
}) => {
	const { langApp } = useContext(AppDataContext);

	return (
		<section className="section bg_mod height_2_mod">
			<div className="section_in v2_mod">
				<div className="gameplan_head_wrap">
					{sectionTitle && (
						<h2 className="section_title h4_mod align_mod color_mod style_mod pos_3_mod">{parse(sectionTitle[langApp])}</h2>
					)}
					{gameplanDescription && (
						<div className="gameplan_head">
							{Object.keys(gameplanDescription).map((item, index) => {
								return <div className="gameplan_head_item" key={index}>{gameplanDescription[item][langApp]}</div>;
							})}
						</div>
					)}
				</div>
				{gameCalendarList && gameCalendarList.length ? (
					<div className="gameplan">
						{gameCalendarList.map(({
							month,
						}, index) => {
							const { games, month_name } = month;

							return (
								<div className="gameplan_item" key={index}>
									{isObject(month_name) && (
										<div className="gameplan_title">{month_name[langApp]}</div>
									)}
									{games && games.length ? (
										<div className="gameplan_list">
											{games.map(({ availability, date }, innerIndex) => {
												const isAvailable = availability === 'true' ? 'available_mod' : '';

												return (
													<div className={`gameplan_list_item ${isAvailable}`} key={innerIndex}>
														<div className="gameplan_item_text">{parse(date[langApp])}</div>
													</div>
												);
											})}
										</div>
									) : null}
								</div>
							);
						})}
					</div>
				) : null}
			</div>
		</section>
	);
};

export default InvitationGameSection;
