/* eslint-disable indent, quotes */
export const TRANSLATION_EN = {
  i18n: {
    language: "Language",
    languageName: "English",
  },
  rackReturnLauncher: {
    continueToReturnForm: "Proceed To Pick-up Form",
    rackIdFieldPlaceholder: "Rack Nr. Ready for Pick-up",
  },
  invitationGame: {
    company: "Company",
    email: "E-mail",
    game1: "Game",
    altGame1: "Alt Game 1",
    altGame2: "Alt Game 2",
    numParticipants: "Number of Participants",
    subjectFmt: "Hockey Game Registration by {}",
    bodyHtmlFmt: "<p>Hockey Game Registration</p><p><ul><li>Company: <strong>{company}</strong></li><li>E-mail: <strong><a href=\"{email}\">{email}</a></strong></li><li>Game: <strong>{game}</strong></li><li>Alt Game 1: <strong>{altGame1}</strong></li><li>Alt Game 2: <strong>{altGame2}</strong></li><li>Number of Participants: <strong>{numParticipants}</strong></li></ul></p>",
  },
  rackReturn: {
    pickupLocation: "Pickup Location",
    pickupStreet: "Street",
    pickupCity: "City",
    pickupCityPlaceholder: "Pickup City",
    doTrackLocation: "✓ position",
    dontTrackLocation: "⏸ position",
    errorTrackingLocation: "✖ Error",
    companyName: "Company",
    enterYourCompany: "Enter the name of your company",
    contactName: "Contact Name",
    enterYourName: "Enter Your Name",
    returnForRackFmt: "Return Form For Rack No. {}",
    rackId: "Rack No.",
    comments: "Comments",
    enterAnyComments: "Enter any Comments",
    submit: "Submit",
    companyNameEmptyError: "Company name is required",
    contactNameEmptyError: "Contact name is required",
    addressEmptyError: "Address oder map location required",
    errorSendingEmail: "⚠ Error while submitting the form",
  },
  rackReturnConfirmation: {
    thankYou: "Thanks. We'll be there shortly ...",
  },
  rackReturnEmail: {
    subjectFmt: "Return of Rack No. {}",
    rackIdReadyAtCompanyInCityFmt: "Rack No. {rackId} is ready for pickup at {companyName} in {city}",
    rackIdReadyInCityFmt: "Rack No. {rackId} is ready for pickup in {city}",
    bodyHtmlFmt: "<p>{heading}</p><p><ul><li>Company: <strong>{companyName}</strong></li><li>Contact: <strong>{contactName}</strong></li><li>Pickup Address: <strong>{address}</strong></li><li>Language: <strong>{language}</strong></li></ul></p><p>Comments:<br />{comments}</p>",
  },
};
