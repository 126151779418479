/* eslint-disable quotes */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ReturnRackConfirmation = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('rackReturnConfirmation');

	useEffect(() => {
		setTimeout(() => { navigate('/'); }, 3000);
	}, []);

	return (
		<div className="rr_form_submitted_confirmation">{t('thankYou')}</div>
	);
};

export default ReturnRackConfirmation;
