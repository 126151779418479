import React, {
	createRef,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Transition } from 'react-transition-group';
import { AppDataContext } from 'context/appData';
import { getPageById } from 'utils';
import { gsap } from 'gsap';
import SplitText from 'libs/SplitText';
import { CustomEase } from 'libs/CustomEase';
import { ReactComponent as ArrowDownIcon } from 'i/icons/arrow_down.svg';

import {
	FaqContainer,
} from 'components/Faq';
import { BtnChat } from 'components/Buttons';
import { UiContext } from 'context/ui';
import { VideoBlock } from 'components/VideoBlock';

gsap.registerPlugin(CustomEase);

const FaqPopup = ({
	isOpen,
}) => {
	const {
		langApp,
		pagesData,
		themeSettings,
	} = useContext(AppDataContext);
	const { showPopupByKey } = useContext(UiContext);

	const [currentPageData, setCurrentPageData] = useState(null);
	const [isAnimationComplete, setAnimationComplete] = useState(false);

	const $popupDecorEl = useRef();
	const $popupInEl = useRef();
	const $popupContainer = useRef();
	const $popupTitle = useRef();
	const $popupDescr = useRef();
	const $btnChat = useRef();
	const $popupScrollIcon = useRef();
	const $btnClosePopup = useRef();
	const $videoBlockEl = createRef();
	const DELAY_FOR_RENDERING_VIDEO = useRef(1);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 544);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.data) {
			setCurrentPageData(preparedCurrentPageData.data);
		}
	};

	const handleClosePopup = () => {
		showPopupByKey();
	};

	const onEntering = node => {
		const tlAnim = gsap.timeline({
			onStart: () => {
				document.body.classList.add('filter_mod');
			},
			onComplete: () => {
				$popupContainer.current.classList.add('overflow_mod');

				gsap.delayedCall(DELAY_FOR_RENDERING_VIDEO.current, () => {
					setAnimationComplete(true);
				});
			},
		});

		let popupTitleWords;
		const $faqTabItem = node.querySelectorAll('.faq_tab_item');

		if ($popupTitle.current) {
			popupTitleWords = new SplitText($popupTitle.current, { type: 'chars', linesClass: 'section_title_line' });
		}

		tlAnim
			.fromTo(node, {
				xPercent: -100,
				opacity: 0,
			}, {
				xPercent: 0,
				opacity: 1,
				ease: 'power3.out',
				duration: 0.8,
			}, 'anim')
			.fromTo($popupInEl.current, {
				opacity: 0,
			}, {
				opacity: 1,
				ease: 'power2.out',
				duration: 0.8,
			}, 'anim+=.4')
			.fromTo($popupDescr.current, {
				x: -150,
			}, {
				x: 0,
				ease: 'power2.out',
				duration: 0.6,
			}, 'anim+=.4')
			.fromTo($popupScrollIcon.current, {
				y: -50,
			}, {
				y: 0,
				ease: 'power2.out',
				duration: 0.6,
			}, 'anim+=.4')
			.fromTo($faqTabItem, {
				opacity: 0,
			}, {
				opacity: 1,
				stagger: 0.2,
				ease: 'power2.out',
				duration: 1,
			}, 'anim+=.4')
			.fromTo(popupTitleWords.chars, {
				y: -150,
			}, {
				y: 0,
				stagger: 0.2,
				ease: 'power2.out',
				duration: 0.6,
			}, 'anim+=.6')
			.fromTo([$btnChat.current, $btnClosePopup.current], {
				opacity: 0,
			}, {
				opacity: 1,
				ease: 'power2.out',
				duration: 0.5,
			}, 'anim+=1');
	};

	const onExit = node => {
		const tlAnim = gsap.timeline({
			onStart: () => {
				$popupContainer.current.classList.remove('overflow_mod');
				document.body.classList.remove('filter_mod');
			},
			onComplete: () => {
				gsap.delayedCall(DELAY_FOR_RENDERING_VIDEO.current, () => {
					setAnimationComplete(false);
				});
			},
		});

		tlAnim
			.fromTo(node, {
				xPercent: 0,
				opacity: 1,
			}, {
				xPercent: -100,
				opacity: 0,
				ease: 'power2.out',
				duration: 1,
			}, 'anim')
			.fromTo($popupInEl.current, {
				opacity: 1,
			}, {
				opacity: 0,
				ease: 'power2.out',
				duration: 0.8,
			}, 'anim+=.4');
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	return (
		currentPageData ? (
			<Transition
				in={isOpen}
				timeout={1800}
				onExit={onExit}
				onEntering={onEntering}
				mountOnEnter
				unmountOnExit
			>
				<div className="popup faq_mod popupFaq">
					<div className="popup_scroll_icon" ref={$popupScrollIcon}>
						<ArrowDownIcon className="icon icon-arrow_down size_mod" />
					</div>
					<div className="popup_in" ref={$popupContainer}>
						{currentPageData.faq_popup_close_button && (
							<button
								className="popup_close popupClose"
								onClick={handleClosePopup}
								type="button"
								ref={$btnClosePopup}
							>
								{currentPageData.faq_popup_close_button[langApp]}
							</button>
						)}
						{currentPageData.faq_chat_button && currentPageData.faq_chat_button.title && (
							<BtnChat
								title={currentPageData.faq_chat_button.title[langApp]}
								ref={$btnChat}
							/>
						)}
						<div className="popup_content popup_mod" ref={$popupInEl}>
							{themeSettings && themeSettings.faqs_page_videos && isAnimationComplete ? (
								<VideoBlock
									videos={themeSettings.faqs_page_videos}
									ref={$videoBlockEl}
								/>
							) : null}
							<div className="popup_decor" ref={$popupDecorEl} />
							<div className="popup_content_in popup_mod">
								{currentPageData.page_title && (
									<div className="popup_title" ref={$popupTitle}>{currentPageData.page_title[langApp]}</div>
								)}
								{currentPageData.page_subtitle && (
									<div className="popup_descr" ref={$popupDescr}>{currentPageData.page_subtitle[langApp]}</div>
								)}
								<FaqContainer />
							</div>
						</div>
						{/* <div className="popup_content_border" /> */}
					</div>
				</div>
			</Transition>
		) : null
	);
};

export default FaqPopup;
