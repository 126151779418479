import React, { useContext } from 'react';
import Select from 'react-select';
import { AppDataContext } from 'context/appData';
import APP_DATA from 'utils/jsonAppData';
import emailjs from '@emailjs/browser';
import { Formik } from 'formik';
import { ButtonSubmit } from 'components/Buttons';
import { useAlert, Alert } from 'components/Alert';
import {
	emailValidation,
	nameValidation,
	selectValidation,
	getValueType,
} from 'utils';
import { useTranslation } from 'react-i18next';

const initialValues = {
	company: '',
	email: '',
	game: '',
	alternativeGame: '',
	alternativeGameSecond: '',
	numberOfParticipants: '',
};

const RegistrationForm = ({
	formNote,
	formFields,
	buttonFormSubmit,
}) => {
	const { langApp } = useContext(AppDataContext);
	const langDE = { lng: 'de' };
	const { t } = useTranslation('invitationGame');
	const [alert, showAlert, hideAlert] = useAlert();

	const numberOfParticipantsValidation = ({
		inputValue,
		requiredErrorMessage,
		validErrorMessage,
	}) => {
		const preparedFieldValue = inputValue.toString();

		if (preparedFieldValue.trim() === '') {
			return requiredErrorMessage || APP_DATA.alertMessages.fieldRequired.en;
		}
		if (/[^1-3]/.test(preparedFieldValue)) {
			return validErrorMessage || APP_DATA.alertMessages.fieldInvalidCharacters.en;
		}

		return null;
	};

	const validate = {
		company: name => nameValidation({
			inputValue: name,
			requiredErrorMessage: APP_DATA.alertMessages.fieldRequired[langApp],
			validErrorMessage: APP_DATA.alertMessages.fieldInvalidCharacters[langApp],
		}),
		email: email => emailValidation({
			inputValue: email,
			requiredErrorMessage: APP_DATA.alertMessages.emailFieldRequired[langApp],
			validErrorMessage: APP_DATA.alertMessages.emailFieldValid[langApp],
		}),
		game: game => selectValidation({
			selectValue: game,
			requiredErrorMessage: APP_DATA.alertMessages.fieldRequired[langApp],
			validErrorMessage: APP_DATA.alertMessages.fieldInvalidCharacters[langApp],
		}),
		alternativeGame: () => { return null; },
		alternativeGameSecond: () => { return null; },
		numberOfParticipants: value => numberOfParticipantsValidation({
			inputValue: value,
			requiredErrorMessage: APP_DATA.alertMessages.fieldRequired[langApp],
			validErrorMessage: APP_DATA.alertMessages.fieldInvalidCharacters[langApp],
		}),
	};

	const sendRegistrationForm = async (values) => {
		const encodeHTML = (str) => {
			return `${str}`.replace(/[&<>"']/g, (tag) => {
				const charsToReplace = {
					'&': '&amp;',
					'<': '&lt;',
					'>': '&gt;',
					'"': '&quot;',
					"'": '&#39;',
				};
				return charsToReplace[tag] || tag;
			});
		};
		const vars = {
			company: encodeHTML(values.company),
			email: encodeHTML(values.email),
			game: encodeHTML(values.game),
			altGame1: encodeHTML(values.alternativeGame),
			altGame2: encodeHTML(values.alternativeGameSecond),
			numParticipants: encodeHTML(values.numberOfParticipants),
			language: encodeHTML(langApp),
		};
		const htmlEmailBody = t('bodyHtmlFmt', langDE).replace(/\{(\w+)\}/g, (_match, key) => vars[key] || '');
		const emailProps = {
			subject: t('subjectFmt', langDE).replace('{}', values.company),
			html_body: htmlEmailBody,
			from_name: 'CDR Webseite',
			from_email: 'configurator@cdr.ch',
			to_email: 'info@cdr.ch',
			cc_email: 'gabriel@cdr.ch',
			bcc_email: 'andy@bitcreed.us',
		};

		await emailjs.send(
			process.env.REACT_APP_EMAILJS_SERVICE_ID,
			process.env.REACT_APP_EMAILJS_GENERIC_TEMPLATE_ID,
			emailProps,
			{
				publicKey: process.env.REACT_APP_EMAILJS_USER_ID,
			},
		);
	};

	return (
		<Formik
			initialValues={initialValues}
			mapPropsToValues={() => {
				return {
					...initialValues,
				};
			}}
			validate={(values) => Object.keys(values).reduce((errors, field) => {
				const error = validate[field](values[field]);
				return {
					...errors,
					...(error && { [field]: error }),
				};
			}, {})}
			onSubmit={async (values, { resetForm, setSubmitting, setFieldValue }) => {
				const hideAlertDuration = 3000;
				try {
					setSubmitting(true);
					await sendRegistrationForm(values);
					setSubmitting(false);
					showAlert(APP_DATA.alertMessages.registrationFormSent[langApp], 'success');

					setTimeout(() => {
						hideAlert();
						resetForm();
						setFieldValue('game', null);
					}, hideAlertDuration);
				} catch (error) {
					setSubmitting(false);
					showAlert(APP_DATA.alertMessages.registrationFormError[langApp], 'danger');

					setTimeout(() => {
						hideAlert();
					}, hideAlertDuration);
				}
			}}
			validateOnChange={false}
			validator={() => ({})}
		>
			{({
				isSubmitting,
				handleSubmit,
				handleBlur,
				handleChange,
				values,
				errors,
				touched,
				setFieldValue,
			}) => {
				return (
					<form className="invitation_form" onSubmit={handleSubmit} noValidate>
						{formFields.map(({
							id,
							labelTitle,
							type,
							name,
							placeholder,
							isRequired,
							options,
						}, index) => {
							const isFieldRequired = isRequired[0] && isRequired[0].toLowerCase() === 'yes';
							const preparedPlaceholder = placeholder && getValueType(placeholder) === 'object' ? placeholder[langApp] : '';

							if (type === 'select' && options) {
								return (
									<div className="invitation_form_item" key={index}>
										<div className="form_field row_mod select_mod">
											<label className="form_field_label row_mod" htmlFor={id}>{labelTitle[langApp]}</label>
											<div className="form_field_input_w">
												<Select
													classNamePrefix="select"
													options={options}
													name={name}
													placeholder={preparedPlaceholder}
													onChange={e => setFieldValue(name, e.label)}
													key={`my_unique_select_key__${name}`}
												/>
												{errors[name] && touched[name] ? (
													<div className="form_field_error">{touched[name] && errors[name]}</div>
												) : null}
											</div>
										</div>
									</div>
								);
							}

							return (
								<div className="invitation_form_item" key={index}>
									<div className="form_field row_mod">
										<label className="form_field_label row_mod" htmlFor={id}>{labelTitle[langApp]}</label>
										<div className="form_field_input_w">
											<input
												className="form_field_input"
												id={id}
												type={type}
												name={name}
												placeholder={preparedPlaceholder}
												required={isFieldRequired}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values[name]}
												min="1"
												max="3"
											/>
											{errors[name] && touched[name] ? (
												<div className="form_field_error">{touched[name] && errors[name]}</div>
											) : null}
										</div>
									</div>
								</div>
							);
						})}
						<div className="invitation_form_bottom">
							{formNote && (
								<div className="invitation_form_bottom_text">{formNote[langApp]}</div>
							)}
							<div className="invitation_form_bottom_btn">
								{alert.visible ? (
									<Alert
										alert={alert}
										hide={hideAlert}
									/>
								) : (
									<ButtonSubmit
										buttonText={buttonFormSubmit[langApp]}
										isDisabled={isSubmitting}
									/>
								)}
							</div>
						</div>
					</form>
				);
			}}
		</Formik>
	);
};

export default RegistrationForm;
