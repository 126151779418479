import React from 'react';

const MainMenuDecors = () => {
	return (
		<div className="menu_bg">
			<div className="menu_bg_in" />
		</div>
	);
};

export default MainMenuDecors;
