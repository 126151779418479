import React from 'react';

import { Spinner } from 'components/Spinner';

const ButtonSubmit = ({
	buttonText,
	isDisabled,
}) => {
	return (
		<button
			className="btn_v2"
			type="submit"
			disabled={isDisabled}
		>
			<span className="btn_in">
				{isDisabled ? (
					<Spinner />
				) : buttonText}
			</span>
		</button>
	);
};

export default ButtonSubmit;
