import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import detectBrowserLanguage from 'detect-browser-language';

import {
	getAllPages,
	getThemeSettings,
	getMainMenuItems,
	getAllFaqData,
	getAllFaqCategories,
} from 'api/data';
import {
	setItemToLocalStorage,
	getItemFromLocalStorage,
	getPageById,
} from 'utils';
import i18n from 'utils/i18n';
import AppDataContext from './appDataContext';

const AppDataState = ({ children }) => {
	const [pagesData, setPagesData] = useState(null);
	const [mainMenuData, setMainMenuData] = useState(null);
	const [themeSettings, setThemeSettings] = useState(null);
	const [allFaqArchive, setAllFaqArchive] = useState(null);
	const [allFaqCategories, setAllFaqCategories] = useState(null);
	const [langApp, setLangApp] = useState(null);
	const [isAppDataLoaded, setAppDataLoaded] = useState(false);
	const [isSignUpBannerActive, setSignUpBannerActive] = useState(false);
	const location = useLocation();

	const languageDetection = () => {
		const userLocalStorageLang = getItemFromLocalStorage('user-language');

		if (userLocalStorageLang) {
			setLangApp(userLocalStorageLang);

			return false;
		}

		const userBrowserLanguage = detectBrowserLanguage();
		let preparedLangApp = 'en';

		if (themeSettings.languages) {
			themeSettings.languages.forEach(({
				code,
			}) => {
				let lowerCaseCodeValue = code.toLowerCase();

				if (userBrowserLanguage.includes(lowerCaseCodeValue)) {
					preparedLangApp = lowerCaseCodeValue;
				}
			});
		}

		setLangApp(preparedLangApp);
		setItemToLocalStorage('user-language', preparedLangApp);

		return null;
	};

	const getAllAppData = async () => {
		const resAllPagesData = await getAllPages();
		const resMainMenu = await getMainMenuItems();
		const themeSettingsData = await getThemeSettings();
		const allFaqData = await getAllFaqData();
		const allFaqCategoriesData = await getAllFaqCategories();

		if (resAllPagesData) {
			setPagesData(resAllPagesData);
		}

		if (themeSettingsData) {
			setThemeSettings(themeSettingsData);
		}

		if (resMainMenu) {
			setMainMenuData(resMainMenu);
		}

		if (allFaqData) {
			setAllFaqArchive(allFaqData);
		}

		if (allFaqCategoriesData) {
			setAllFaqCategories(allFaqCategoriesData);
		}

		setAppDataLoaded(true);
	};

	useEffect(() => {
		// skip loading app-data on /retour/ pages
		if (location && location.pathname.indexOf('/retour/') < 0) {
			getAllAppData();
		}
	}, []);

	useEffect(() => {
		if (!pagesData) return;
		const invitationPageData = getPageById(pagesData, 283); // empty array if page is disabled
		if (invitationPageData && invitationPageData.length > 0 && !isSignUpBannerActive) {
			setSignUpBannerActive(true);
		}
	}, [pagesData]);

	useEffect(() => {
		if (themeSettings) {
			languageDetection();
		}
	}, [themeSettings]);

	useEffect(() => {
		if (!langApp) return;
		i18n.changeLanguage(langApp);
	}, [langApp]);

	const appDataContext = useMemo(
		() => ({
			themeSettings,
			langApp,
			setLangApp,
			pagesData,
			setPagesData,
			mainMenuData,
			allFaqArchive,
			setAllFaqArchive,
			allFaqCategories,
			setAllFaqCategories,
			isAppDataLoaded,
			isSignUpBannerActive,
			setSignUpBannerActive,
		}),
		[themeSettings, langApp, pagesData, mainMenuData, allFaqArchive, allFaqCategories, isAppDataLoaded, isSignUpBannerActive],
	);

	return (
		<AppDataContext.Provider
			value={appDataContext}
		>
			{children}
		</AppDataContext.Provider>
	);
};

export default AppDataState;
