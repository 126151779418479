import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';
import { InvitationUsefulList } from 'components/InvitationUsefulList';
import parse from 'html-react-parser';

const InvitationUsefulLinksSection = ({
	sectionTitle,
	linksList,
}) => {
	const { langApp } = useContext(AppDataContext);

	return (
		<section className="section bg_mod height_2_mod">
			<div className="section_in v2_mod">
				{sectionTitle && (
					<h2 className="section_title h4_mod align_mod color_mod style_mod offset_2_mod pos_3_mod">{parse(sectionTitle[langApp])}</h2>
				)}
				<InvitationUsefulList linksList={linksList} />
			</div>
		</section>
	);
};

export default InvitationUsefulLinksSection;
