/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-danger */
import DOMPurify from 'isomorphic-dompurify';

const SanitizedText = ({ children }) => {
	if (!children) return null;

	const sanitized = DOMPurify.sanitize(children);

	return <span dangerouslySetInnerHTML={{ __html: sanitized }} />;
};

export default SanitizedText;
