import React, { useContext, useState, useEffect } from 'react';
import { AppDataContext } from 'context/appData';
import { getPageById } from 'utils';
import {
	InvitationProgramSection,
	InvitationAdditionalSection,
	InvitationGameSection,
	InvitationRegistrationSection,
	InvitationUsefulLinksSection,
} from 'components/Sections';

const InvitationPage = () => {
	const { pagesData } = useContext(AppDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	// const [pageTitle, setPageTitle] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 283); // empty array if page is disabled
		const preparedCurrentPageData = currentData[0]; // and this becomes undefined

		if (preparedCurrentPageData && preparedCurrentPageData.data) {
			setCurrentPageData(preparedCurrentPageData.data);

			// if (preparedCurrentPageData.title && preparedCurrentPageData.title.rendered) {
			// 	setPageTitle(preparedCurrentPageData.title.rendered);
			// }
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	useEffect(() => {
		if (currentPageData) {
			document.body.classList.add('static_mod');
		}

		return () => {
			document.body.classList.remove('static_mod');
		};
	}, [currentPageData]);

	return (
		currentPageData ? (
			<>
				<InvitationProgramSection
					sectionTitle={currentPageData.page_title}
					sectionSubtitle={currentPageData.page_subtitle}
					sectionDescr={currentPageData.page_description}
					sectionTitleDecorImg={currentPageData.page_title_logo}
					listHeadTitle={currentPageData.program_title}
					scheduleList={currentPageData.events}
				/>
				<InvitationAdditionalSection
					sectionTitle={currentPageData.info_title}
					listArray={currentPageData.info_details}
				/>
				<InvitationGameSection
					sectionTitle={currentPageData.gameplan_title}
					gameplanDescription={currentPageData.gameplan_description}
					gameCalendarList={currentPageData.game_calendar}
				/>
				<InvitationRegistrationSection
					sectionTitle={currentPageData.form_title}
					sectionDescr={currentPageData.form_description}
					registrationFormFields={currentPageData.formFields}
					registrationFormNote={currentPageData.form_notes}
					buttonFormSubmit={currentPageData.buttonFormSubmit}
				/>
				<InvitationUsefulLinksSection
					sectionTitle={currentPageData.links_title}
					linksList={currentPageData.links}
				/>
			</>
		) : null
	);
};

export default InvitationPage;
