import React from 'react';

const HomeSliderControls = ({
	sliderInstance,
	mobileLogo,
	sliderLength,
	slideActiveIndex,
}) => {
	const moveToSlide = slideIndex => {
		if (slideIndex !== undefined && sliderInstance.current) {
			sliderInstance.current.slickGoTo(slideIndex);
		}
	};

	return (
		<div className="home_slider_controls_w">
			{sliderLength && (
				<div className="home_slider_count_list">
					{[...Array(sliderLength)].map((x, index) => {
						const slideCountTitle = sliderLength < 10 ? `0${index + 1}` : index + 1;
						const isActiveItem = index === slideActiveIndex ? 'active_mod' : '';

						return (
							<button
								className={`home_slider_count_item ${isActiveItem}`}
								key={index}
								type="button"
								onClick={() => moveToSlide(index)}
							>
								{slideCountTitle}
							</button>
						);
					})}
				</div>
			)}
			{mobileLogo && mobileLogo.length ? (
				<div className="mobile_logo_block">
					<div className="mobile_logo_list">
						{mobileLogo.map(({ img, alt }, index) => {
							return (
								<div className="mobile_logo_item" key={index}>
									<div className="mobile_logo_item_in">
										<img className="mobile_logo_img" src={img} alt={alt} />
									</div>
								</div>
							);
						})}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default HomeSliderControls;
