import React, {
	useContext, useState, useEffect, useRef,
	useMemo,
} from 'react';
import { AppDataContext } from 'context/appData';
import { getPageById } from 'utils';
import { gsap } from 'gsap';

import { HomeSlider } from 'components/HomeSlider';
import { SanitizedText } from 'components/SanitizedText';
import { UiContext } from 'context/ui';
import { GLOBAL_PAGE_ANIM_DELAY } from 'utils/constant';

const HomePage = () => {
	const { pagesData, langApp } = useContext(AppDataContext);
	const { isPreloaderActive } = useContext(UiContext);
	const [currentPageData, setCurrentPageData] = useState(null);
	const $homeAnnouncement = useRef(null);

	const [homeAnnouncementLoadAnimation] = useState(gsap.timeline({
		paused: true,
	}));

	const sliderData = useMemo(() => {
		if (!currentPageData) return null;

		const sd = Object.keys(currentPageData)
			.filter(o => o.startsWith('slide_'))
			.reduce((newObj, k) => {
				// eslint-disable-next-line no-param-reassign
				newObj[k] = currentPageData[k];
				return newObj;
			}, {});

		if (!currentPageData.announcement.show_announcement) return sd;

		// Insert the announcement as "overlay" key into the first
		const announcement = currentPageData.announcement && (
			<div ref={$homeAnnouncement} className="home_announcement">
				<SanitizedText>
					{currentPageData.announcement[langApp]}
				</SanitizedText>
			</div>
		);
		return {
			...Object.keys(sd).map((k, i) => {
				return i === 0 ? { ...sd[k], overlay: announcement } : sd[k];
			}),
		};
	}, [currentPageData]);

	useEffect(() => {
		if (pagesData) {
			const currentData = getPageById(pagesData, 34);
			const preparedCurrentPageData = currentData[0];

			if (preparedCurrentPageData && preparedCurrentPageData.data) {
				setCurrentPageData(preparedCurrentPageData.data);
			}
		}
	}, [pagesData]);

	useEffect(() => {
		if ($homeAnnouncement.current) {
			homeAnnouncementLoadAnimation
				.addLabel('start')
				.to($homeAnnouncement.current, {
					opacity: 1,
					duration: 0,
				}, 'start')
				.fromTo($homeAnnouncement.current, {
					yPercent: -100,
					opacity: 0,
				}, {
					yPercent: 0,
					opacity: 1,
					stagger: -0.08,
				}, 'start');
		}
	}, [$homeAnnouncement.current]);

	useEffect(() => {
		if (!isPreloaderActive) {
			gsap.delayedCall(GLOBAL_PAGE_ANIM_DELAY, () => {
				homeAnnouncementLoadAnimation.play();
			});
		}
	}, [isPreloaderActive]);

	return (
		currentPageData ? (
			<section className="section no_offset_mod">
				<HomeSlider sliderData={sliderData} />
			</section>
		) : null
	);
};

export default HomePage;
