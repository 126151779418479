import React, { useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import MapMarker from './MapMarker';

const Map = ({
	mapData,
	isMapOpen,
	setMapOpen,
}) => {
	const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
	const LATITUDE = Number(mapData.coordinates.latitude);
	const LONGITUDE = Number(mapData.coordinates.longitude);
	const MAP_DEFAULT_CENTER = { lat: LATITUDE, lng: LONGITUDE };

	const $mapMarker = useRef(null);

	return (
		<GoogleMapReact
			bootstrapURLKeys={{ key: MAP_API_KEY }}
			defaultCenter={MAP_DEFAULT_CENTER}
			defaultZoom={18}
			options={map => ({
				mapTypeId: map.MapTypeId.ROADMAP,
				mapId: 'acc786445d9ab435',
				disableDefaultUI: true,
				clickableIcons: false,
			})}
			yesIWantToUseGoogleMapApiInternals
		>
			<MapMarker
				ref={$mapMarker}
				lat={LATITUDE}
				lng={LONGITUDE}
				markerPopupData={mapData.map_popup}
				isMapOpen={isMapOpen}
				setMapOpen={setMapOpen}
			/>
		</GoogleMapReact>
	);
};

export default Map;
