import React, { useContext, useState, useEffect } from 'react';
import { AppDataContext } from 'context/appData';

import { getPageById } from 'utils';

const ConfiguratorPage = () => {
	const { pagesData, langApp } = useContext(AppDataContext);
	const [currentPageData, setCurrentPageData] = useState(null);

	const getPageData = () => {
		const currentData = getPageById(pagesData, 1110);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.data) {
			setCurrentPageData(preparedCurrentPageData.data);
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	return (
		<section className="section height_mod center_mod">
			<div className="section_in">
				{currentPageData && currentPageData.title ? (
					<h2 className="section_title v2_mod offset_mod style_mod">{currentPageData.title[langApp]}</h2>
				) : null}
			</div>
		</section>
	);
};

export default ConfiguratorPage;
