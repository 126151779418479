/* eslint-disable camelcase */
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import Slider from 'react-slick';
import { gsap } from 'gsap';
import { SplitText } from 'libs/SplitText';
import { useSwipeable } from 'react-swipeable';
import { GLOBAL_PAGE_ANIM_DELAY, TABLET_MEDIA_POINT } from 'utils/constant';
import { debounce } from 'utils';
import { useNavigate } from 'react-router-dom';
import { UiContext } from 'context/ui';
import HomeSliderControls from './HomeSliderControls';
import HomeSliderItem from './HomeSliderItem';

const HomeSlider = ({
	sliderData,
}) => {
	const {
		actionBannerAnimTl,
		faqBlockAnimTl,
		windowWidth,
		isPreloaderActive,
		appPopups,
	} = useContext(UiContext);
	const navigate = useNavigate();

	const [slidesAnimArray, setSlidesAnimArray] = useState([]);
	const [slideActiveIndex, setSlideActiveIndex] = useState(0);
	const [isHomeSliderLoadAnimationDone, setHomeSliderLoadAnimationDone] = useState(false);

	const $homeSliderWel = useRef();
	const homeSliderInstance = useRef();
	const isSliderAnimActive = useRef(false);
	const currentSlideRef = useRef(0);

	const [sliderOnLoadAnimation] = useState(gsap.timeline({
		paused: true,
		onComplete: () => {
			setHomeSliderLoadAnimationDone(true);
		},
	}));

	useEffect(() => {
		if (sliderData && homeSliderInstance.current && $homeSliderWel.current) {
			const draftslidesAnimArray = [];

			$homeSliderWel.current.querySelectorAll('.home_slider_w_item').forEach($slide => {
				const $slideInEl = $slide.querySelector('.home_slider_item');
				const $slideImage = $slide.querySelector('.home_slide_w_image');
				const $mainTitle = $slide.querySelector('.homeSlideMainTitle');
				const $aboutVideoTitle = $slide.querySelector('.aboutVideoTitle');
				const $playVideoBtn = $slide.querySelector('.playVideoBtn');
				const $slideVideosItem = $slide.querySelectorAll('.homeSlideVideoItem');
				const $slideVideoItemW = $slide.querySelector('.homeSlideVideoItemW');
				const $slideInfoItems = $slide.querySelectorAll('.home_slider_info_item');
				const $slideInfoItemsDecor = $slide.querySelectorAll('.homeSliderInfoDecorLine');
				const $crowdImages = $slide.querySelectorAll('.crowd_block_w_img');

				let mainTitleSplitted;

				if ($mainTitle) {
					mainTitleSplitted = new SplitText($mainTitle, { type: 'words', linesClass: 'home_split_title' });
				}

				let aboutVideoTitleSplitted;

				if ($aboutVideoTitle) {
					aboutVideoTitleSplitted = new SplitText($aboutVideoTitle, { type: 'chars,words', linesClass: 'home_split_letters' });
				}

				const slideInfo = {
					el: $slide,
					innerItemEl: $slideInEl,
					slideImage: $slideImage,
					mainTitle: $mainTitle,
					mainTitleWords: mainTitleSplitted,
					aboutVideoTitle: $aboutVideoTitle,
					aboutVideoTitleWords: aboutVideoTitleSplitted,
					videoWrapper: $slideVideoItemW,
					videos: $slideVideosItem,
					videoBtn: $playVideoBtn,
					crowdImages: $crowdImages,
					infoItems: $slideInfoItems,
					infoItemsDecor: $slideInfoItemsDecor,
				};

				draftslidesAnimArray.push(slideInfo);
			});

			gsap.set(draftslidesAnimArray[0].innerItemEl, {
				xPercent: -100,
			});

			gsap.set(draftslidesAnimArray[0].slideImage, {
				opacity: 1,
			});

			gsap.to(draftslidesAnimArray[0].videoWrapper, {
				opacity: 1,
			});

			setSlidesAnimArray(draftslidesAnimArray);
		}
	}, [sliderData]);

	const homeSliderOnLoadAnimation = () => {
		let $homeSliderCountItems = document.querySelectorAll('.home_slider_count_item');
		let $homeSliderContentContainer = document.querySelector('.home_slider_content_container');

		sliderOnLoadAnimation
			.addLabel('start')
			.to($homeSliderContentContainer, {
				opacity: 1,
				duration: 0,
			}, 'start')
			.fromTo(slidesAnimArray[0].mainTitleWords.words, {
				yPercent: -100,
				opacity: 0,
			}, {
				yPercent: 0,
				opacity: 1,
				stagger: -0.08,
			}, 'start');

		if ($homeSliderCountItems && $homeSliderCountItems.length) {
			if (windowWidth >= TABLET_MEDIA_POINT) {
				sliderOnLoadAnimation
					.fromTo($homeSliderCountItems, {
						x: '10rem',
					}, {
						x: 0,
						stagger: 0.15,
						duration: 0.8,
						onComplete: () => {
							// handleSlideVideoState(slidesAnimArray[0].videos, 'play');
						},
					});
			} else {
				sliderOnLoadAnimation
					.fromTo($homeSliderCountItems, {
						y: '2rem',
						opacity: 0,
					}, {
						y: 0,
						opacity: 1,
						stagger: 0.15,
						duration: 0.4,
					});
			}
		}

		if (actionBannerAnimTl.progress() === 0) {
			sliderOnLoadAnimation
				.add(actionBannerAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start+=.8');
		}

		if (faqBlockAnimTl.progress() === 0) {
			sliderOnLoadAnimation
				.add(faqBlockAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start+=1');
		}
	};

	const homeSliderAnimation = (currentSlide, nextSlide) => {
		isSliderAnimActive.current = true;
		setSlideActiveIndex(nextSlide);
		currentSlideRef.current = nextSlide;

		const tlSlide = gsap.timeline({
			onComplete: () => {
				isSliderAnimActive.current = false;
			},
		});

		const xPercentFromValues = currentSlide < nextSlide ? [-100, 0] : [-100, -200];
		const xPercentToValues = currentSlide < nextSlide ? [-200, -100] : [0, -100];

		tlSlide
			.addLabel('slide')
			.fromTo([slidesAnimArray[currentSlide].innerItemEl, slidesAnimArray[nextSlide].innerItemEl], {
				xPercent: gsap.utils.wrap(xPercentFromValues),
				opacity: gsap.utils.wrap([1, 0]),
			}, {
				xPercent: gsap.utils.wrap(xPercentToValues),
				opacity: gsap.utils.wrap([0, 1]),
				duration: 1.2,
				ease: 'power2.inOut',
			}, 'slide')
			.fromTo([slidesAnimArray[currentSlide].slideImage, slidesAnimArray[nextSlide].slideImage], {
				opacity: gsap.utils.wrap([1, 0]),
			}, {
				opacity: gsap.utils.wrap([0, 1]),
				duration: 0.8,
				ease: 'power2.inOut',
			}, 'slide')
			.fromTo(slidesAnimArray[nextSlide].videoWrapper, {
				opacity: 0,
			}, {
				opacity: 1,
				duration: 0.8,
				immediateRender: false,
				ease: 'power2.inOut',
			}, 'slide');

		if (slidesAnimArray[nextSlide].aboutVideoTitleWords && slidesAnimArray[nextSlide].aboutVideoTitleWords.chars) {
			tlSlide
				.fromTo(slidesAnimArray[nextSlide].aboutVideoTitleWords.chars, {
					y: '-20rem',
					opacity: 0,
				}, {
					y: '0rem',
					opacity: 1,
					stagger: 0.02,
					ease: 'power2.out',
				}, 'slide+=.8');
		}

		if (slidesAnimArray[nextSlide].crowdImages && slidesAnimArray[nextSlide].crowdImages.length) {
			tlSlide
				.fromTo([
					slidesAnimArray[nextSlide].crowdImages[0],
					slidesAnimArray[nextSlide].crowdImages[slidesAnimArray[nextSlide].crowdImages.length - 1],
				], {
					x: gsap.utils.wrap(['-50vw', '5rem']),
				}, {
					x: 0,
					duration: 1.5,
					ease: 'power2.out',
				}, 'slide+=.8');
		}

		if (slidesAnimArray[nextSlide].videoBtn) {
			tlSlide
				.fromTo(slidesAnimArray[nextSlide].videoBtn, {
					scale: 0,
				}, {
					scale: 1,
					opacity: 1,
					ease: 'power2.out',
				}, 'slide+=1');
		}

		if (windowWidth >= TABLET_MEDIA_POINT) {
			if (slidesAnimArray[nextSlide].infoItems && slidesAnimArray[nextSlide].infoItems.length) {
				tlSlide
					.fromTo(slidesAnimArray[nextSlide].infoItems, {
						x: gsap.utils.wrap([0, 0, 80, 0]),
						y: gsap.utils.wrap([-80, 0, 0, 80]),
					}, {
						x: 0,
						y: 0,
						duration: 1.2,
						ease: 'power1.out',
					}, 'slide+=.6');
			}

			if (slidesAnimArray[nextSlide].infoItemsDecor && slidesAnimArray[nextSlide].infoItemsDecor.length) {
				tlSlide
					.fromTo(slidesAnimArray[nextSlide].infoItemsDecor[1], {
						opacity: 0,
					}, {
						opacity: 1,
						ease: 'power2.out',
					}, 'slide+=1.5');
			}
		}
	};

	const onBeforeChange = (currentSlide, nextSlide) => {
		if (currentSlide !== nextSlide) {
			homeSliderAnimation(currentSlide, nextSlide);
		}

		return false;
	};

	const handleSlickPrev = () => {
		homeSliderInstance.current.slickPrev();
	};

	const handleSlickNext = () => {
		const sliderLength = Object.keys(sliderData).length;
		if (currentSlideRef.current === sliderLength - 1) {
			navigate('/offer');
		} else {
			homeSliderInstance.current.slickNext();
		}
	};

	const handlers = useSwipeable({
		onSwipedLeft: () => {
			if (!isSliderAnimActive.current && !appPopups.faq.isOpen) {
				handleSlickNext(slideActiveIndex + 1);
			}
		},
		onSwipedRight: () => {
			if (!isSliderAnimActive.current && !appPopups.faq.isOpen) {
				handleSlickPrev();
			}
		},
		preventDefaultTouchmoveEvent: true,
		trackTouch: true,
	});

	const handleScroll = (e) => {
		if (homeSliderInstance.current && !isSliderAnimActive.current && !appPopups.faq.isOpen) {
			if (e.deltaY < 0 || e.deltaX < 0) {
				handleSlickPrev();
			} else if (e.deltaY > 0 || e.deltaX > 0) {
				handleSlickNext();
			}
		}
	};

	const debouncedHandleScroll = useCallback(debounce(handleScroll, 35), []);

	useEffect(() => {
		if (sliderData) {
			if (windowWidth >= TABLET_MEDIA_POINT) {
				window.addEventListener('wheel', debouncedHandleScroll);
			}
		}

		return () => {
			if (windowWidth >= TABLET_MEDIA_POINT) {
				window.removeEventListener('wheel', debouncedHandleScroll);
			}
		};
	}, [sliderData]);

	useEffect(() => {
		if (slidesAnimArray.length) {
			homeSliderOnLoadAnimation();
		}
	}, [slidesAnimArray]);

	useEffect(() => {
		if (!isPreloaderActive) {
			gsap.delayedCall(GLOBAL_PAGE_ANIM_DELAY, () => {
				sliderOnLoadAnimation.play();
			});
		}
	}, [isPreloaderActive]);

	return (
		<div className="home_slide_w_container" {...handlers}>
			<div className="home_slider_w" ref={$homeSliderWel}>
				{sliderData ? (
					<>
						<HomeSliderControls
							sliderInstance={homeSliderInstance}
							sliderLength={Object.keys(sliderData).length}
							slideActiveIndex={slideActiveIndex}
						/>
						<Slider
							className="home_slider"
							ref={homeSliderInstance}
							{...{
								infinite: false,
								slidesToShow: 1,
								slidesToScroll: 1,
								speed: 0,
								dots: false,
								arrows: false,
								touchMove: false,
								waitForAnimate: false,
								accessibility: false,
								cssEase: 'none',
								beforeChange: (current, next) => onBeforeChange(current, next),
							}}
						>
							{Object.keys(sliderData).map((key, index) => {
								const {
									slide_description,
									slide_bg: slideBgImage,
									videos,
									vimeo_video_title,
									close_video_button_title,
									vimeo_video,
									cover_image,
									about_list,
									overlay,
								} = sliderData[key];

								if (key === 'slide_2') return null; // doesn't seem to exist in WP
								return (
									<div className="home_slider_w_item" key={index}>
										{slideBgImage ? (
											<div
												className="home_slide_w_image"
												style={{
													backgroundImage: `url(${slideBgImage})`,
												}}
											/>
										) : null}
										<HomeSliderItem
											key={index}
											slideKey={key}
											slideIndex={index}
											slideActiveIndex={slideActiveIndex}
											slidesAnimArray={slidesAnimArray}
											slideDescription={slide_description}
											slideHeadText={vimeo_video_title}
											videos={videos.filter(o => o.webm || o.mov)}
											vimeoVideoId={vimeo_video}
											closeVideoButtonTitle={close_video_button_title}
											videoCover={cover_image}
											gridInfoItems={about_list}
											isHomeSliderLoadAnimationDone={isHomeSliderLoadAnimationDone}
										/>
										{overlay}
									</div>
								);
							})}
						</Slider>
					</>
				) : null}
			</div>
		</div>
	);
};

export default HomeSlider;
