import React, { useEffect, useState } from 'react';
import { VIDEO_LOADING_STATUS } from 'utils/constant';
import HomeSliderVideo from './HomeSliderVideo';

const HomeSliderVideoContainerIn = ({
	srcMov,
	srcWebm,
	slideIndex,
	videoIndex,
	slideActiveIndex,
	videoLoadingStatus,
	setVideoLoadingStatus,
	renderedVideos,
	slideKey,
	currentActiveVideoIndex,
	setCurrentActiveVideoIndex,
	isVideoPopupOpen,
}) => {
	// to track the download of two videos
	const [videoLoadedState, setVideoLoadedState] = useState(() => {
		if (slideKey === 'slide_3') {
			return [false];
		}

		return [false, false];
	});
	const [isVideoFullyLoaded, setVideoFullyLoaded] = useState(false);
	const [isVideosPlaying, setVideosPlaying] = useState(false);

	useEffect(() => {
		if (
			videoLoadingStatus
			&& videoLoadedState.every(Boolean)
			&& !isVideoFullyLoaded
		) {
			const clonedVideLoadingStatus = [...videoLoadingStatus];

			if (clonedVideLoadingStatus[videoIndex]) {
				clonedVideLoadingStatus[videoIndex].status = VIDEO_LOADING_STATUS.loaded;

				setVideoLoadingStatus(clonedVideLoadingStatus);

				setVideoFullyLoaded(true);
			}
		}
	}, [videoLoadedState]);

	// FOR FIRST VIDEO
	useEffect(() => {
		// when two videos loaded, and current slide active, play
		if (
			videoLoadedState.every(Boolean)
			&& slideActiveIndex === slideIndex
			&& videoIndex === 0
		) {
			setVideosPlaying(true);
		}
	}, [videoLoadedState]);

	// play video if fully loaded
	useEffect(() => {
		if (currentActiveVideoIndex === videoIndex && isVideoFullyLoaded && !isVideosPlaying) {
			setVideosPlaying(true);
		}
	}, [currentActiveVideoIndex, isVideoFullyLoaded]);

	useEffect(() => {
		// pause video while move to another slide
		if (slideActiveIndex !== slideIndex && isVideosPlaying) {
			setVideosPlaying(false);
		}

		// play video after move from another slide
		if (slideActiveIndex === slideIndex && currentActiveVideoIndex === videoIndex && isVideoFullyLoaded) {
			setVideosPlaying(true);
		}
	}, [slideActiveIndex, isVideosPlaying]);

	useEffect(() => {
		if (currentActiveVideoIndex === videoIndex) {
			if (isVideoPopupOpen && isVideosPlaying) {
				setVideosPlaying(false);
			} else {
				setVideosPlaying(true);
			}
		}
	}, [isVideoPopupOpen]);

	return (
		<div className="home_slider_video_container__in">
			{slideKey !== 'slide_3' ? (
				<>
					<div className="home_slider_video_container__col home_slider_video_container__col--left_lvl_state">
						<HomeSliderVideo
							srcWebm={srcWebm}
							srcMov={srcMov}
							slideIndex={slideIndex}
							videoIndex={videoIndex}
							videoIndexPlaying={0}
							slideActiveIndex={slideActiveIndex}
							setVideoLoadedState={setVideoLoadedState}
							videoLoadedState={videoLoadedState}
							renderedVideos={renderedVideos}
							isVideosPlaying={isVideosPlaying}
							setVideosPlaying={setVideosPlaying}
							setCurrentActiveVideoIndex={setCurrentActiveVideoIndex}
						/>
					</div>
					<div className="home_slider_video_container__col home_slider_video_container__col--right_lvl_state">
						<HomeSliderVideo
							srcWebm={srcWebm}
							srcMov={srcMov}
							videoIndexPlaying={1}
							slideIndex={slideIndex}
							videoIndex={videoIndex}
							slideActiveIndex={slideActiveIndex}
							setVideoLoadedState={setVideoLoadedState}
							videoLoadedState={videoLoadedState}
							renderedVideos={renderedVideos}
							isVideosPlaying={isVideosPlaying}
							setVideosPlaying={setVideosPlaying}
							setCurrentActiveVideoIndex={setCurrentActiveVideoIndex}
						/>
					</div>
				</>
			) : (
				<div className="home_slider_video_container__single">
					<HomeSliderVideo
						srcWebm={srcWebm}
						srcMov={srcMov}
						videoIndexPlaying={0}
						slideIndex={slideIndex}
						videoIndex={videoIndex}
						slideActiveIndex={slideActiveIndex}
						setVideoLoadedState={setVideoLoadedState}
						videoLoadedState={videoLoadedState}
						renderedVideos={renderedVideos}
						isVideosPlaying={isVideosPlaying}
						setVideosPlaying={setVideosPlaying}
						setCurrentActiveVideoIndex={setCurrentActiveVideoIndex}
					/>
				</div>
			)}
		</div>
	);
};

export default HomeSliderVideoContainerIn;
