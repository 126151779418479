import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';

const HeaderInfoList = () => {
	const { themeSettings, langApp } = useContext(AppDataContext);

	return (
		<div className="header_info_list_w">
			<ul className="header_info_list">
				{themeSettings && themeSettings.contact_info ? (
					Object.keys(themeSettings.contact_info).map((key, index) => {
						let linkType = null;

						if (key.includes('phone')) {
							linkType = 'tel';
						}

						if (key.includes('email')) {
							linkType = 'mailto';
						}

						return (
							<li className="header_info_item" key={index}>
								{linkType ? (
									<a className="header_info_link" href={`${linkType}:${themeSettings.contact_info[key].trim()}`}>{themeSettings.contact_info[key]}</a>
								) : (
									<div className="header_info_item_descr">{themeSettings.contact_info[key][langApp]}</div>
								)}
							</li>
						);
					})
				) : null}
			</ul>
		</div>
	);
};

export default HeaderInfoList;
