/* eslint-disable indent, quotes */
export const TRANSLATION_DE = {
  i18n: {
    language: "Sprache",
    languageName: "Deutsch",
  },
  rackReturnLauncher: {
    continueToReturnForm: "Weiter zum Abholformular",
    rackIdFieldPlaceholder: "Gestell Nr. bereit zur Abholung",
  },
  invitationGame: {
    company: "Firma",
    email: "Email",
    game1: "Spiel",
    altGame1: "Ausweichspiel 1",
    altGame2: "Ausweichspiel 2",
    numParticipants: "Anzahl Teilnehmer",
    subjectFmt: "Hockeyspiel Registrierung von {}",
    bodyHtmlFmt: "<p>Hockeyspiel Registrierung</p><p><ul><li>Firma: <strong>{company}</strong></li><li>Email: <strong><a href=\"{email}\">{email}</a></strong></li><li>Spiel: <strong>{game}</strong></li><li>Ausweichspiel 1: <strong>{altGame1}</strong></li><li>Ausweichspiel 2: <strong>{altGame2}</strong></li><li>Anz Teilnehmer: <strong>{numParticipants}</strong></li></ul></p>",
  },
  rackReturn: {
    pickupLocation: "Abholort",
    pickupStreet: "Strasse",
    pickupCity: "Ort",
    pickupCityPlaceholder: "Abholort",
    doTrackLocation: "✓ Position",
    dontTrackLocation: "⏸ Position",
    errorTrackingLocation: "✖ Fehler",
    companyName: "Firma",
    enterYourCompany: "Gib deine Firma ein",
    contactName: "Kontakt",
    enterYourName: "Gib deinen Namen ein",
    returnForRackFmt: "Retourformular für Gestell Nr. {}",
    rackId: "Gestell Nr.",
    comments: "Kommentare",
    enterAnyComments: "Weitere Anmerkungen",
    submit: "Absenden",
    companyNameEmptyError: "Firma wird benötigt",
    contactNameEmptyError: "Kontaktname wird benötigt",
    addressEmptyError: "Adresse oder Kartenpunkt wird benötigt",
    errorSendingEmail: "⚠ Fehler beim Absenden des Formulars",
  },
  rackReturnConfirmation: {
    thankYou: "Vielen Dank. Wir sind bald dort ...",
  },
  rackReturnEmail: {
    subjectFmt: "Gestell Retour Nr. {}",
    rackIdReadyAtCompanyInCityFmt: "Gestell Nr. {rackId} ist Abholbereit bei {companyName} in {city}",
    rackIdReadyInCityFmt: "Gestell Nr. {rackId} ist Abholbereit in {city}",
    bodyHtmlFmt: "<p>{heading}</p><p><ul><li>Firma: <strong>{companyName}</strong></li><li>Kontakt: <strong>{contactName}</strong></li><li>Adresse: <strong>{address}</strong></li><li>Koordinaten: <strong>{coordinates}</strong></li><li>Koordinatenquelle: <strong>{locationType}</strong></li><li>Sprache: <strong>{language}</strong></li><li>Einstieg: <strong>{pageEntryPoint}</strong></li></ul></p><p>Anmerkungen:<br />{comments}</p><br><p style=\"font-size: small; font-family: monospace;\">Browser: {navigator}</p>",
  },
};
