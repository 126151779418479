import Bg404Page from 'i/bg_404.jpg';

const APP_DATA = {
	header: {
		menuTriggerButtonTitle: {
			de: 'menü',
			fr: 'menu',
			it: 'menu',
			en: 'menu',
		},
		menuTriggerButtonCloseTitle: {
			de: 'schliessen',
			fr: 'fermer',
			it: 'chiudere',
			en: 'close',
		},
		menuContent: {
			cookiePreferences: {
				de: 'Cookie Einstellungen',
				fr: 'Paramètres des Cookies',
				it: 'Impostazioni Cookie',
				en: 'Cookie Settings',
			},
		},
	},
	notFoundPage: {
		bgImage: Bg404Page,
		bgImageAlt: 'Error 404',
	},
	faqPage: {
		typeYourQuestion: {
			en: 'Type your question',
			fr: 'Tape ta question',
			it: 'Scrivi la tua domanda',
			de: 'Tippe deine Frage ein',
		},
		back: {
			en: 'Back',
			fr: 'Retour',
			it: 'Indietro',
			de: 'Zurück',
		},
		foundMultipleAnswersFmt: {
			en: 'Found {numAnswers} answers',
			fr: '{numAnswers} réponses trouvées',
			it: 'Trovate {numAnswers} risposte',
			de: 'Es wurden {numAnswers} Antworten gefunden',
		},
		foundSingleAnswer: {
			en: 'Found one answer',
			fr: 'Une réponse trouvée',
			it: 'Trovata una risposta',
			de: 'Eine Antwort gefunden',
		},
	},
	alertMessages: {
		fieldRequired: {
			en: 'This field is required',
			de: 'Dieses Feld wird benötigt',
			fr: 'Ce champ est requis',
			it: 'Questo campo è obbligatorio',
		},
		fieldInvalidCharacters: {
			en: 'Invalid characters',
			de: 'Ungültige Zeichen',
			fr: 'Caractères invalides',
			it: 'Caratteri non validi',
		},
		emailFieldRequired: {
			en: 'Email is required',
			de: 'Email ist erforderlich',
			fr: 'L\'email est requise',
			it: 'L\'email è richiesta',
		},
		emailFieldValid: {
			en: 'Please enter a valid email',
			de: 'Gib bitte eine gültige Email Adresse an',
			fr: 'Entre une adresse email valide',
			it: 'Inserisci un\'email valida',
		},
		registrationFormSent: {
			en: 'Form sent',
			de: 'Formular abgesendet',
			fr: 'Formulaire envoyé',
			it: 'Modulo inviato',
		},
		registrationFormError: {
			en: 'The form was not submitted, try again later',
			de: 'Formular nicht übermittelt, versuche es später noch einmal',
			fr: 'Le formulaire n\'a pas été soumis, réessaie plus tard',
			it: 'Il modulo non è stato inviato, riprova più tardi',
		},
	},
};

export default APP_DATA;
