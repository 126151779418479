import { AppDataContext } from 'context/appData';
import React, { useContext, useEffect, useState } from 'react';
import { filterByCategoryId } from 'utils';
import { ALL_CATEGORY_ID } from './constant';

const FaqNav = ({
	setFilteredFaqArchive,
}) => {
	const { allFaqCategories, langApp, allFaqArchive } = useContext(AppDataContext);
	const [activeCategoryId, setActiveCategoryId] = useState();

	const handleOnClick = id => {
		if (id !== undefined) {
			if (id !== ALL_CATEGORY_ID) {
				const filteredByCategory = filterByCategoryId(allFaqArchive, id);
				setFilteredFaqArchive(filteredByCategory);
			} else {
				setFilteredFaqArchive([...allFaqArchive]);
			}

			setActiveCategoryId(id);
		}
	};

	useEffect(() => {
		if (allFaqCategories && allFaqCategories.length) {
			const categoryId = allFaqCategories[0].id;

			setActiveCategoryId(categoryId);
			handleOnClick(categoryId);
		}
	}, [allFaqCategories]);

	return (
		allFaqCategories && allFaqCategories.length ? (
			<div className="faq_tab_list">
				<ul className="faq_tab_list_in">
					{allFaqCategories.map(({
						name,
						count,
						id,
					}) => {
						const activeClass = activeCategoryId === id ? 'active_mod' : '';
						const isAllCategory = id === ALL_CATEGORY_ID;

						if (count || isAllCategory) {
							return (
								<li className="faq_tab_item" key={id}>
									<button
										className={`faq_tab_item_in ${activeClass}`}
										type="button"
										onClick={() => handleOnClick(id)}
									>
										{name && name[langApp]}
										{count && count !== undefined ? (
											<div className="faq_tab_link_count">{count}</div>
										) : null}
										{!count && (
											<div className="faq_tab_link_count">{allFaqArchive.length}</div>
										)}
									</button>
								</li>
							);
						}
						return false;
					})}
				</ul>
			</div>
		) : null
	);
};

export default FaqNav;
