import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getItemFromLocalStorage } from 'utils';

import { TRANSLATION_DE } from './locales/de/translation';
import { TRANSLATION_FR } from './locales/fr/translation';
import { TRANSLATION_IT } from './locales/it/translation';
import { TRANSLATION_EN } from './locales/en/translation';

export const availableLanguages = {
	de: { flag: '🇩🇪', nativeText: TRANSLATION_DE.i18n.languageName },
	en: { flag: '🇬🇧', nativeText: TRANSLATION_EN.i18n.languageName },
	fr: { flag: '🇫🇷', nativeText: TRANSLATION_FR.i18n.languageName },
	it: { flag: '🇮🇹', nativeText: TRANSLATION_IT.i18n.languageName },
};

// const detectedLng = i18n.services.languageDetector.detect();
// const availableLanguages = ['de', 'fr', 'it', 'en_US']; // List your languages here
// const fallbackLng = 'en_US';

// let resources = {};

// for (let lng of availableLanguages) {
//     try {
//         resources[lng] = await fetchTranslation(lng);
//     } catch (error) {
//         console.error(`Failed to load ${lng} translation`, error);
//         if (lng === detectedLng) {
//             // If the detected language fails to load, load the fallback language
//             resources[fallbackLng] = await fetchTranslation(fallbackLng);
//         }
//     }
// }
// // BEGIN remove this code
const resources = {
	de: TRANSLATION_DE,
	fr: TRANSLATION_FR,
	it: TRANSLATION_IT,
	en_US: TRANSLATION_EN,
};
// // END remove this code

const useUserLanguage = () => {
	const userLocalStorageLang = getItemFromLocalStorage('user-language');
	const i18nLang = i18n.language;
	if (userLocalStorageLang !== i18nLang) {
		i18n.changeLanguage(i18nLang);
	}
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		keySeparator: false,
		fallbackLng: 'en_US',
		interpolation: {
			escapeValue: false,
		},
	})
	.then(useUserLanguage);

export default i18n;
