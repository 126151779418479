import React,
{
	useRef,
	useContext,
	useState,
	useEffect,
} from 'react';
import { gsap } from 'gsap';
import { SplitText } from 'libs/SplitText';

import { ServiceSlider, ServicesFilter } from 'components/Services';

import { AppDataContext } from 'context/appData';
import { GLOBAL_PAGE_ANIM_DELAY, TABLET_MEDIA_POINT } from 'utils/constant';
import { getPageById } from 'utils';
import { UiContext } from 'context/ui';

gsap.registerPlugin(SplitText);

const ServicesPage = () => {
	const { pagesData, langApp } = useContext(AppDataContext);
	const {
		actionBannerAnimTl,
		faqBlockAnimTl,
		windowWidth,
		isPreloaderActive,
	} = useContext(UiContext);

	const [currentPageData, setCurrentPageData] = useState(null);
	const $sectionTitle = useRef();

	const [pageAnimation] = useState(gsap.timeline({
		paused: true,
	}));

	const getPageData = () => {
		const currentData = getPageById(pagesData, 42);
		const preparedCurrentPageData = currentData[0];

		if (preparedCurrentPageData && preparedCurrentPageData.data) {
			setCurrentPageData(preparedCurrentPageData.data);
		}
	};

	const pageAnim = () => {
		const sectionTitleLetters = new SplitText($sectionTitle.current, { type: 'chars' });

		pageAnimation
			.addLabel('start')
			.fromTo(sectionTitleLetters.chars, {
				yPercent: -200,
				opacity: 0,
			}, {
				yPercent: 0,
				opacity: 1,
				stagger: 0.1,
				duration: 0.6,
			}, 'start')
			.fromTo('.section_services_link', {
				xPercent: gsap.utils.wrap([-100, 0, 100]),
				yPercent: gsap.utils.wrap([0, 100, 0]),
				opacity: 0,
			}, {
				xPercent: 0,
				yPercent: 0,
				opacity: 1,
				stagger: {
					from: 'edges',
					amount: 0.3,
				},
			}, 'start+=.3')
			.fromTo('.section_services_li', {
				opacity: 0,
			}, {
				opacity: 1,
				stagger: {
					from: 'edges',
					amount: 0.3,
				},
			}, 'start+=.3');

		if (actionBannerAnimTl.progress() === 0 && faqBlockAnimTl.progress() === 0) {
			pageAnimation
				.add(actionBannerAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start')
				.add(faqBlockAnimTl.tweenFromTo('onload_anim_start', 'onload_anim_end'), 'start+=.2');
		}

		if (windowWidth >= TABLET_MEDIA_POINT) {
			pageAnimation
				.fromTo('.section_slide_item_in', {
					yPercent: 100,
					xPercent: 20,
					opacity: 0,
					rotateY: -25,
				}, {
					yPercent: 0,
					xPercent: 0,
					opacity: 1,
					rotateY: 0,
					duration: 1,
					stagger: 0.3,
					ease: 'power2.out',
				}, 'start+=1');
		} else {
			pageAnimation
				.fromTo('.section_slide_item_in', {
					xPercent: 20,
					opacity: 0,
				}, {
					xPercent: 0,
					opacity: 1,
					duration: 1,
					stagger: 0.3,
					ease: 'power2.out',
				}, 'start+=1');
		}

		if (windowWidth < TABLET_MEDIA_POINT) {
			gsap.delayedCall(3, () => {
				pageAnimation
					.fromTo('.slick-dots', {
						opacity: 0,
					}, {
						opacity: 1,
					}, '-=2');
			});
		}
	};

	useEffect(() => {
		if (pagesData) {
			getPageData();
		}
	}, [pagesData]);

	useEffect(() => {
		if (currentPageData) {
			pageAnim();
		}
	}, [currentPageData]);

	useEffect(() => {
		if (!isPreloaderActive) {
			gsap.delayedCall(GLOBAL_PAGE_ANIM_DELAY, () => {
				pageAnimation.play();
			});
		}
	}, [isPreloaderActive]);

	return (
		currentPageData ? (
			<section className="section height_mod center_mod">
				<div className="section_in">
					{currentPageData.page_title && (
						<h2 className="section_title h1_mod accent_mod style_mod weight_mod pos_mod offset_3_mod" ref={$sectionTitle}>{currentPageData.page_title[langApp]}</h2>
					)}
					{currentPageData.services_filter && currentPageData.services_filter.length ? (
						<ServicesFilter
							filterList={currentPageData.services_filter}
						/>
					) : null}
					{currentPageData.services_list && currentPageData.services_list.length ? (
						<ServiceSlider
							servicesList={currentPageData.services_list}
						/>
					) : null}
				</div>
			</section>
		) : null

	);
};

export default ServicesPage;
