import React from 'react';
// import classNames from 'classnames';

const Spinner = (/* {darkMod} */) => {
	// const spinnerClasses = classNames('spinner', { dark_mod: darkMod });

	return (
		<div className="spinner_wrap">
			<div className="spinner">
				<div className="spinner-border" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		</div>
	);
};

export default Spinner;
