import React, { useContext } from 'react';
import { searchFieldValidation } from 'utils';
import { ReactComponent as SearchIcon } from 'i/icons/search.svg';
import { AppDataContext } from 'context/appData';
import APP_DATA from 'utils/jsonAppData';

const FaqSearch = ({
	inputSearchValue,
	setInputSearchValue,
	setFilteredFaqArchive,
	setSearchApplied,
}) => {
	const { langApp, allFaqArchive } = useContext(AppDataContext);

	const onChange = e => {
		const { value } = e.target;

		setInputSearchValue(value);
	};

	const onSubmit = e => {
		e.preventDefault();

		if (searchFieldValidation(inputSearchValue) && allFaqArchive) {
			const preparedInputValue = inputSearchValue.trim();
			const regExpPattern = new RegExp(`^.*\\b(${preparedInputValue})\\b.*$`, 'gmi');

			const filteredFaqData = allFaqArchive.filter((item) => {
				const titleResult = regExpPattern.test(item.question[langApp]);
				const descrResult = regExpPattern.test(item.answer[langApp]);

				if (titleResult || descrResult) return true;

				return false;
			});

			setFilteredFaqArchive(filteredFaqData);
			setSearchApplied(true);
		}
	};

	return (
		<form className="faq_search" onSubmit={onSubmit}>
			<div className="form_field">
				<input
					className="form_field_input"
					id="faq_input_1"
					type="text"
					name="text_field_1"
					placeholder={APP_DATA.faqPage.typeYourQuestion[langApp]}
					required="required"
					onChange={onChange}
					value={inputSearchValue}
				/>
			</div>
			<button className="faq_search_btn" type="submit">
				<SearchIcon className="icon icon-search size_mod" />
			</button>
		</form>
	);
};

export default FaqSearch;
