const FAQ_NAV = [
	{
		title: 'GENERAL',
	},
	{
		title: 'PAYMENTS',
	},
	{
		title: 'SHIPPINGS',
	},
	{
		title: 'STANDARDS',
	},
	{
		title: 'GLASS',
	},
	{
		title: 'RETURNS',
	},
	{
		title: 'REFUNDS',
	},
];

const ALL_CATEGORY_ID = 12;

export {
	FAQ_NAV,
	ALL_CATEGORY_ID,
};
