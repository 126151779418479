import { AppDataContext } from 'context/appData';
import React, { useContext } from 'react';
import parse from 'html-react-parser';

const InvitationUsefulList = ({
	linksList,
}) => {
	const { langApp } = useContext(AppDataContext);

	return (
		linksList && linksList.length ? (
			<ul className="useful_list">
				{linksList.map(({
					link,
					url,
				}, index) => {
					return (
						<li className="useful_item" key={`${index}_link`}>
							{link ? (
								<a
									className="useful_link"
									target="_blank"
									href={url}
									rel="noreferrer"
								>
									{parse(link[langApp])}
								</a>
							) : null}
						</li>
					);
				})}
			</ul>
		) : null
	);
};

export default InvitationUsefulList;
