import { InvitationScheduleList } from 'components/InvitationScheduleList';
import { AppDataContext } from 'context/appData';
import React, { useContext } from 'react';
import parse from 'html-react-parser';

const InvitationProgramSection = ({
	sectionTitle,
	sectionSubtitle,
	sectionDescr,
	sectionTitleDecorImg,
	sectionTitleDecorImgAlt,
	listHeadTitle,
	scheduleList,
}) => {
	const { langApp } = useContext(AppDataContext);

	return (
		<section className="section bg_mod v3_mod height_2_mod">
			<div className="section_in">
				<h2 className="section_title h1_mod accent_mod style_mod weight_mod pos_mod">
					{sectionTitle && (
						parse(sectionTitle[langApp])
					)}
					{sectionTitleDecorImg && (
						<div className="section_title_logo">
							<img className="section_title_logo_img" src={sectionTitleDecorImg} alt={sectionTitleDecorImgAlt} />
						</div>
					)}
				</h2>
				{sectionSubtitle && (
					<div className="section_title h3_mod accent_mod weight_mod pos_2_mod offset_2_mod">{parse(sectionSubtitle[langApp])}</div>
				)}
				{sectionDescr && (
					<div className="section_descr size_2_mod offset_in_mod offset_2_mod">{parse(sectionDescr[langApp])}</div>
				)}
				{listHeadTitle && (
					<h2 className="section_title h4_mod align_mod color_mod style_mod offset_2_mod pos_3_mod">{listHeadTitle[langApp]}</h2>
				)}
				{scheduleList && scheduleList.length ? (
					<InvitationScheduleList scheduleList={scheduleList} />
				) : null}
			</div>
		</section>
	);
};

export default InvitationProgramSection;
