/* eslint-disable indent, quotes */
export const TRANSLATION_IT = {
  i18n: {
    language: "Lingua",
    languageName: "Italiano",
  },
  rackReturnLauncher: {
    continueToReturnForm: "Vai al modulo di ritiro",
    rackIdFieldPlaceholder: "Rack n° pronto per il ritiro",
  },
  invitationGame: {
    company: "Company",
    email: "E-mail",
    game1: "Game",
    altGame1: "Alt Game 1",
    altGame2: "Alt Game 2",
    numParticipants: "Number of Participants",
    subjectFmt: "Hockey Game Registration by {}",
    bodyHtmlFmt: "<p>Hockey Game Registration</p><p><ul><li>Company: <strong>{company}</strong></li><li>E-mail: <strong><a href=\"{email}\">{email}</a></strong></li><li>Game: <strong>{game}</strong></li><li>Alt Game 1: <strong>{altGame1}</strong></li><li>Alt Game 2: <strong>{altGame2}</strong></li><li>Number of Participants: <strong>{numParticipants}</strong></li></ul></p>",
  },
  rackReturn: {
    pickupLocation: "Luogo di ritiro",
    pickupStreet: "Indirizzo",
    pickupCity: "Lieu",
    pickupCityPlaceholder: "Luogo di ritiro",
    doTrackLocation: "✓ posizione",
    dontTrackLocation: "⏸ posizione",
    errorTrackingLocation: "✖ Errore",
    companyName: "Azienda",
    enterYourCompany: "Inserisci la tua azienda",
    contactName: "Contatto",
    enterYourName: "Inserisci il tuo nome",
    returnForRackFmt: "Modulo di restituzione per il scaffale n. {}",
    rackId: "Numero scaffale",
    comments: "Commenti",
    enterAnyComments: "Aggiungi commenti",
    submit: "Invia",
    companyNameEmptyError: "Nome del cliente richiesto",
    contactNameEmptyError: "Il nome del contatto è richiesto",
    addressEmptyError: "Indirizzo o punto sulla mappa richiesto",
    errorSendingEmail: "⚠ Errore nell'invio del modulo",
  },
  rackReturnConfirmation: {
    thankYou: "Grazie. Saremo lì a breve ...",
  },
  rackReturnEmail: {
    subjectFmt: "Ritorno di Scaffale n. {}",
    rackIdReadyAtCompanyInCityFmt: "Lo scaffale n. {rackId} è pronto per il ritiro presso {companyName} a {city}.",
    rackIdReadyInCityFmt: "Lo scaffale n. {rackId} è pronto per il ritiro a {city}.",
    bodyHtmlFmt: "<p>{heading}</p><p><ul><li>Azienda: <strong>{companyName}</strong></li><li>Contatto: <strong>{contactName}</strong></li><li>Indirizzo: <strong>{address}</strong></li><li>Lingua: <strong>{language}</strong></li></ul></p><p>Commenti:<br />{comments}</p>",
  },
};
