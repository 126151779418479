import React, { useRef, useEffect } from 'react';
import { detectSafariBrowser } from 'utils';

const VideoBlockItem = ({
	srcWebm,
	srcMov,
	videoIndex,
	videoLoadedState,
	setVideoLoadedState,
	videoIndexPlaying,
	isVideosPlaying,
	setVideosPlaying,
	renderedVideos,
	setCurrentActiveVideoIndex,
}) => {
	const $videoRef = useRef();
	const isSafariBrowser = useRef(detectSafariBrowser());

	const onCanPlayThroughHandler = () => {
		if ($videoRef.current.readyState >= 3) {
			const duplicatedVideoState = [...videoLoadedState];

			duplicatedVideoState[videoIndexPlaying] = true;

			setVideoLoadedState(duplicatedVideoState);
		}
	};

	const onEndedHandler = () => {
		if (videoIndex === renderedVideos.length - 1) {
			setCurrentActiveVideoIndex(0);
		} else {
			setCurrentActiveVideoIndex(videoIndex + 1);
		}

		setVideosPlaying(false);
	};

	useEffect(() => {
		if (isVideosPlaying) {
			$videoRef.current.currentTime = 0;
			$videoRef.current.play();
		} else {
			$videoRef.current.pause();
		}
	}, [isVideosPlaying]);

	const renderSourceTag = () => {
		if (isSafariBrowser.current && srcMov) {
			return (
				<source src={srcMov} type='video/mp4; codecs="hvc1"' />
			);
		}

		return (
			<source src={srcWebm} type="video/webm" />
		);
	};

	useEffect(() => {
		if ($videoRef.current) {
			$videoRef.current.load();
		}
	}, []);

	return (
		<video
			className="video_block__element"
			playsInline
			muted
			preload="auto"
			ref={$videoRef}
			onCanPlayThrough={onCanPlayThroughHandler}
			onEnded={onEndedHandler}
		>
			{renderSourceTag()}
		</video>
	);
};

export default VideoBlockItem;
