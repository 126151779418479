import React, { useEffect, useRef, useState } from 'react';
import { cloneObjectWithoutReference } from 'utils';
import { VIDEO_LOADING_STATUS } from 'utils/constant';
import HomeSliderVideoContainerIn from './HomeSliderVideoContainerIn';

const HomeSliderVideoContainer = ({
	videos,
	slideKey,
	slideActiveIndex,
	slideIndex,
	isVideoPopupOpen,
}) => {
	const [allVideos, setAllVideos] = useState(null);
	const [renderedVideos, setRenderedVideos] = useState(null);
	const [videoLoadingStatus, setVideoLoadingStatus] = useState(null);
	const [currentActiveVideoIndex, setCurrentActiveVideoIndex] = useState(0);
	const [currentRenderedVideoIndex, setCurrentRenderedVideoIndex] = useState(0);

	const slideDuration = useRef(1200);

	const createVideosArray = () => {
		const clonedVideos = [...videos];
		const firstVideoIndex = Math.floor(Math.random() * clonedVideos.length);
		const firstVideoElement = clonedVideos.splice(firstVideoIndex, 1);

		setAllVideos(firstVideoElement.concat(clonedVideos));
		setRenderedVideos(firstVideoElement);

		const videoState = videos.map(() => {
			const data = {
				status: VIDEO_LOADING_STATUS.pending,
			};

			return data;
		});

		setVideoLoadingStatus(videoState);
	};

	const renderNewVideo = () => {
		if (
			videoLoadingStatus[currentRenderedVideoIndex].status === VIDEO_LOADING_STATUS.loaded
			&& (renderedVideos.length - 1) <= (allVideos.length - 1)
		) {
			if (currentRenderedVideoIndex + 1 <= allVideos.length - 1) {
				const nextRenderedVideoIndex = currentRenderedVideoIndex + 1;
				const newVideo = cloneObjectWithoutReference(allVideos[nextRenderedVideoIndex]);
				const updatedActiveVideos = [...renderedVideos];

				updatedActiveVideos.push(newVideo);

				setRenderedVideos(updatedActiveVideos);
				setCurrentRenderedVideoIndex(nextRenderedVideoIndex);
			}
		}
	};

	useEffect(() => {
		if (
			videos
			&& videos.length > 1
			&& slideActiveIndex === slideIndex
			&& !renderedVideos
		) {
			setTimeout(() => {
				createVideosArray();
			}, slideDuration.current);
		}
	}, [slideActiveIndex]);

	useEffect(() => {
		if (videoLoadingStatus && allVideos) {
			renderNewVideo();
		}
	}, [videoLoadingStatus]);

	return (
		<div className="home_slider_video_container homeSlideVideoItemW">
			{renderedVideos && renderedVideos.length ? (
				renderedVideos.map(({
					mov,
					webm,
				}, index) => {
					if (!mov && !webm) return null;
					return (
						<HomeSliderVideoContainerIn
							key={index}
							srcMov={mov}
							srcWebm={webm}
							slideKey={slideKey}
							videoIndex={index}
							slideActiveIndex={slideActiveIndex}
							slideIndex={slideIndex}
							videoLoadingStatus={videoLoadingStatus}
							renderedVideos={renderedVideos}
							setVideoLoadingStatus={setVideoLoadingStatus}
							currentActiveVideoIndex={currentActiveVideoIndex}
							setCurrentActiveVideoIndex={setCurrentActiveVideoIndex}
							isVideoPopupOpen={isVideoPopupOpen}
						/>
					);
				})
			) : null}
		</div>
	);
};

export default HomeSliderVideoContainer;
