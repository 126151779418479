import React, { useContext } from 'react';
import {
	setItemToLocalStorage,
} from 'utils';
import { AppDataContext } from 'context/appData';

const MainMenuLangList = React.forwardRef(({
	_props,
}, ref) => {
	const { langApp, themeSettings } = useContext(AppDataContext);
	const handleOnClick = language => {
		const preparedLanguageValue = language.toLowerCase();

		setItemToLocalStorage('user-language', preparedLanguageValue);
		document.location.reload();
	};

	return (
		themeSettings && themeSettings.languages ? (
			<ul className="main_menu_lang_list" ref={ref}>
				{themeSettings.languages.map(({
					code,
				}, index) => {
					let upperCaseCodeValue = code.toUpperCase();
					let lowerCaseCodeValue = code.toLowerCase();

					return (
						<li className={`main_menu_lang_item mod_${index + 1}`} key={index}>
							<button
								className={`main_menu_lang_link ${langApp === lowerCaseCodeValue ? 'active_mod' : ''}`}
								onClick={() => handleOnClick(code)}
								type="button"
							>
								{upperCaseCodeValue}
							</button>
						</li>
					);
				})}
			</ul>
		) : null
	);
});

export default MainMenuLangList;
